import React, { useState, useEffect } from 'react';
import { IoExitOutline, IoMenuOutline, IoSunnyOutline, IoMoonOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const companyName = localStorage.getItem('companyName');
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    updateTheme(isDarkMode);
  }, []);

  const updateTheme = (isDark) => {
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
    updateTheme(newDarkMode);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('companyName');
    localStorage.removeItem("permissions");
    navigate('/login');
  };

  return (
    <header className="bg-white dark:bg-gray-800 px-4 py-3 flex justify-between items-center shadow-sm w-full z-20">
      <div className="flex items-center">
        <button
          className="hamburger-button md:hidden mr-4 text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-200"
          onClick={toggleSidebar}
          aria-label="Toggle menu"
        >
          <IoMenuOutline size={24} />
        </button>
        <h1 className="text-xl font-bold text-blue-600 dark:text-blue-400">SBS Flow</h1>
      </div>
      <div className="flex items-center space-x-3">
        <button
          onClick={toggleDarkMode}
          className="flex items-center text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-200 bg-gray-100 dark:bg-gray-700 rounded-full py-2 px-3 hover:bg-blue-50 dark:hover:bg-gray-600"
          aria-label="Toggle dark mode"
        >
          {darkMode ? <IoSunnyOutline className="text-xl" /> : <IoMoonOutline className="text-xl" />}
          <span className="ml-1 text-sm font-medium hidden sm:inline">
            {darkMode ? 'Açık Mod' : 'Koyu Mod'}
          </span>
        </button>
        <div className="flex items-center bg-gray-100 dark:bg-gray-700 rounded-full py-1 px-3">
          <div className="w-8 h-8 bg-gradient-to-r from-blue-500 to-blue-600 rounded-full overflow-hidden flex items-center justify-center text-white font-semibold shadow-md">
            {companyName ? companyName.charAt(0).toUpperCase() : 'F'}
          </div>
          <span className="ml-2 text-sm text-gray-700 dark:text-gray-300 font-medium hidden sm:inline">
            {companyName}
          </span>
        </div>
        <button
          onClick={handleLogout}
          className="flex items-center text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-200 bg-gray-100 dark:bg-gray-700 rounded-full py-2 px-3 hover:bg-blue-50 dark:hover:bg-gray-600"
          aria-label="Logout"
        >
          <IoExitOutline className="text-xl" />
          <span className="ml-1 text-sm font-medium hidden sm:inline">Çıkış</span>
        </button>
      </div>
    </header>
  );
};

export default Header;