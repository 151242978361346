import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  FiSearch,
  FiLoader,
  FiArrowLeft,
  FiList,
  FiClock,
  FiX,
  FiChevronRight,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import axiosInstance from "../../api/api";
import { motion, AnimatePresence } from "framer-motion";
import CustomerModal from "../customers/CustomerModal";

const OverduePayments = () => {
  const [overduePayments, setOverduePayments] = useState([]);
  const [recentPayments, setRecentPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("overdue");
  const [expandedDescription, setExpandedDescription] = useState(null);
  const descriptionRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [packages, setPackages] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState({
    amount: "",
    payed_amount: "",
    discount: "0",
    payment_type: "cash",
    description: "",
  });
  const [packageSearchTerm, setPackageSearchTerm] = useState("");

  const fetchOverduePayments = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/api/payment-overdue/${searchTerm ? `?search=${searchTerm}` : ""}`
      );

      const today = new Date();
      const sortedPayments = response.data.sort((a, b) => {
        const daysOverdueA =
          (today - new Date(a.payment_date)) / (1000 * 60 * 60 * 24);
        const daysOverdueB =
          (today - new Date(b.payment_date)) / (1000 * 60 * 60 * 24);
        return daysOverdueB - daysOverdueA;
      });

      setOverduePayments(sortedPayments);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Veri çekme hatası");
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  const fetchRecentPayments = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        "/api/history-payment/?limit=100&ordering=-created_at"
      );
      setRecentPayments(response.data.results);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Son ödemeleri çekme hatası"
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchPackages = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/api/company-package/");
      setPackages(response.data);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Paket çekme hatası");
    }
  }, []);

  useEffect(() => {
    if (activeTab === "overdue") {
      fetchOverduePayments();
    } else {
      fetchRecentPayments();
    }
  }, [activeTab, fetchOverduePayments, fetchRecentPayments]);

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        descriptionRef.current &&
        !descriptionRef.current.contains(event.target)
      ) {
        setExpandedDescription(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (activeTab === "overdue") {
      fetchOverduePayments();
    }
  };

  const truncateDescription = (description, maxLength = 20) => {
    if (!description) return "";
    if (description.length <= maxLength) return description;
    return description.substr(0, maxLength) + "...";
  };

  const toggleDescription = (id) => {
    setExpandedDescription((prev) => (prev === id ? null : id));
  };

  const handleCustomerClick = useCallback(async (customer) => {
    try {
      const response = await axiosInstance.get(`/api/customer/${customer.id}/`);
      setSelectedCustomer(response.data);
      setPaymentInfo({
        amount: response.data.payments[0]?.amount || "",
        payed_amount: response.data.payments[0]?.payed_amount || "",
        discount: response.data.payments[0]?.discount || "0",
        payment_type: response.data.payments[0]?.payment_type || "cash",
        description: response.data.payments[0]?.description || "",
      });
      setIsModalOpen(true);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Müşteri bilgisi çekme hatası");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCustomer(prev => ({ ...prev, [name]: value }));
  };

  const handlePackageChange = (e) => {
    const packageId = parseInt(e.target.value);
    setSelectedCustomer(prev => ({
      ...prev,
      company_packages: e.target.checked
        ? [...(prev.company_packages || []), packageId]
        : (prev.company_packages || []).filter(id => id !== packageId)
    }));
  };

  const handlePaymentInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.patch(`/api/customer/${selectedCustomer.id}/`, selectedCustomer);
      if (selectedCustomer.payments && selectedCustomer.payments.length > 0) {
        await axiosInstance.patch(`/api/payment/${selectedCustomer.payments[0].id}/`, paymentInfo);
      } else {
        await axiosInstance.post("/api/payment/", { ...paymentInfo, customer: selectedCustomer.id });
      }
      setIsModalOpen(false);
      fetchOverduePayments();
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Müşteri güncelleme hatası");
    }
  };

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <div className="flex items-center mb-4 md:mb-0">
            <Link
              to="/customers"
              className="mr-4 p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition duration-300"
            >
              <FiArrowLeft size={24} />
            </Link>
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-blue-600 dark:text-blue-400">
              {activeTab === "overdue" ? "Borcu Olanlar" : "Son Ödemeler"}
            </h2>
          </div>
          <div className="flex items-center space-x-4">
            <motion.div className="bg-gray-300 dark:bg-gray-700 p-1 rounded-lg flex gap-2">
              <motion.button
                layout
                onClick={() => setActiveTab("overdue")}
                className={`px-4 py-2 rounded-md ${activeTab === "overdue"
                    ? "bg-blue-500 text-white"
                    : "bg-transparent text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600"
                  }`}
              >
                <FiList className="inline-block mr-2" />
                Borcu Olanlar
              </motion.button>
              <motion.button
                layout
                onClick={() => setActiveTab("recent")}
                className={`px-4 py-2 rounded-md ${activeTab === "recent"
                    ? "bg-blue-500 text-white"
                    : "bg-transparent text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600"
                  }`}
              >
                <FiClock className="inline-block mr-2" />
                Son Ödemeler
              </motion.button>
            </motion.div>
          </div>
        </div>

        {activeTab === "overdue" && (
          <form
            onSubmit={handleSearchSubmit}
            className="relative flex-grow md:max-w-md mb-6"
          >
            <input
              type="text"
              placeholder="Ara..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full px-4 py-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-500"
            />
            <button
              type="submit"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500"
            >
              <FiSearch />
            </button>
          </form>
        )}

        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <FiLoader className="animate-spin text-blue-500 text-4xl" />
                <span className="ml-2 text-lg">Yükleniyor...</span>
              </div>
            ) : (
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
                {activeTab === "overdue" ? (
                  overduePayments.length > 0 ? (
                    <div className="max-h-[500px] overflow-y-auto">
                      <table className="w-full min-w-max table-auto">
                        <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
                          <tr>
                            {[
                              "İsim",
                              "Telefon",
                              "E-posta",
                              "Kalan Borç",
                              "Ödeme Tarihi",
                            ].map((header, index) => (
                              <th
                                key={index}
                                className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                          {overduePayments.map((payment, index) => {
                            const paymentDate = new Date(payment.payment_date);

                            return (
                              <tr
                                key={index}
                                className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200 cursor-pointer"
                                onClick={() => handleCustomerClick(payment)}
                              >
                                <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                  {payment.name}
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                  {payment.phone || "-"}
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                  {payment.email || "-"}
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                  {payment.amount.toFixed(2)} TL
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                  {paymentDate.toLocaleDateString("tr-TR")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center h-32">
                      <p className="text-lg text-gray-600 dark:text-gray-400">
                        Ödemesi olan müşteri yoktur.
                      </p>
                    </div>
                  )
                ) : recentPayments.length > 0 ? (
                  <div className="max-h-[500px] overflow-y-auto">
                    <table className="w-full min-w-max table-auto">
                      <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
                        <tr>
                          {[
                            "İsim",
                            "Kalan Borç",
                            "Ödenen Tutar",
                            "Ödeme Tipi",
                            "Açıklama",
                            "Ödeme Tarihi",
                          ].map((header, index) => (
                            <th
                              key={index}
                              className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                        {recentPayments.map((payment, index) => {
                          const paymentDate = new Date(payment.created_at);

                          return (
                            <tr
                              key={index}
                              className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                            >
                              <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                {payment.customer_name}
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                {parseFloat(payment.amount).toFixed(2)} TL
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                {parseFloat(payment.payed_amount).toFixed(2)} TL
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                {payment.payment_type === "eft"
                                  ? "EFT"
                                  : payment.payment_type === "cash"
                                    ? "Nakit"
                                    : payment.payment_type === "card"
                                      ? "Kart"
                                      : payment.payment_type === "other"
                                        ? "Diğer"
                                        : payment.payment_type}
                              </td>
                              <td className="px-4 py-3 text-gray-800 dark:text-gray-200 relative">
                                <div className="flex items-center justify-between">
                                  {payment.description ? (
                                    <button
                                      onClick={() =>
                                        toggleDescription(payment.id)
                                      }
                                      className="text-left hover:underline focus:outline-none flex items-center"
                                    >
                                      <span>
                                        {truncateDescription(
                                          payment.description
                                        )}
                                      </span>
                                      <FiChevronRight className="ml-1" />
                                    </button>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </div>
                                {expandedDescription === payment.id &&
                                  payment.description && (
                                    <div
                                      ref={descriptionRef}
                                      className="absolute z-10 mt-2 p-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg w-64"
                                    >
                                      <button
                                        onClick={() =>
                                          setExpandedDescription(null)
                                        }
                                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                                      >
                                        <FiX />
                                      </button>
                                      <p className="text-sm text-gray-600 dark:text-gray-400">
                                        {payment.description}
                                      </p>
                                    </div>
                                  )}
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                                {paymentDate.toLocaleString("tr-TR")}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-32">
                    <p className="text-lg text-gray-600 dark:text-gray-400">
                      Son ödeme bulunamadı.
                    </p>
                  </div>
                )}
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        <CustomerModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedCustomer={selectedCustomer}
          packages={packages}
          packageSearchTerm={packageSearchTerm}
          setPackageSearchTerm={setPackageSearchTerm}
          handleInputChange={handleInputChange}
          handlePackageChange={handlePackageChange}
          handleSubmit={handleSubmit}
          paymentInfo={paymentInfo}
          handlePaymentInputChange={handlePaymentInputChange}
        />

        <ErrorMessage
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      </div>
    </div>
  );
};

export default OverduePayments;