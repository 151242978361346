import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Customers from './pages/Customers';
import Packets from './pages/Packets';
import Appointments from './pages/Appointments';
import Settings from './pages/Settings';
import Products from './pages/Products';
import Allappointments from './pages/Allappointments';
import Statistics from './pages/Statistics';
import OverduePayments from './components/customers/OverduePayments';

import Layout from './components/Layout';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? <Layout>{children}</Layout> : <Navigate to="/login" replace />;
};

const StatisticsRoute = ({ children }) => {
  const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
  const hasStatisticsPermission = permissions.includes('view_statistics');
  return hasStatisticsPermission ? children : <Navigate to="/home" replace />;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute><Navigate to="/home" replace /></PrivateRoute>} />
        <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/customers" element={<PrivateRoute><Customers /></PrivateRoute>} />
        <Route path="/packets" element={<PrivateRoute><Packets /></PrivateRoute>} />
        <Route path="/appointments" element={<PrivateRoute><Appointments /></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
        <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />
        <Route path="/allappointments" element={<PrivateRoute><Allappointments /></PrivateRoute>} />
        <Route path="/statistics" element={<PrivateRoute><StatisticsRoute><Statistics /></StatisticsRoute></PrivateRoute>} />
        <Route path="/overduepayments" element={<PrivateRoute><OverduePayments /></PrivateRoute>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;