import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";

const ErrorMessage = ({
  message,
  type = "error",
  onClose,
  duration = 3000,
}) => {
  const isError = type === "error";

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [message, duration, onClose]);

  return (
    <AnimatePresence>
      {message && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className={`fixed bottom-4 right-4 px-4 py-2 rounded-md shadow-lg z-50 flex items-center ${
            isError ? "bg-red-500 text-white" : "bg-green-500 text-white"
          }`}
        >
          {isError ? (
            <FiAlertCircle className="mr-2 text-xl" />
          ) : (
            <FiCheckCircle className="mr-2 text-xl" />
          )}
          <span className="font-medium">{message}</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ErrorMessage;