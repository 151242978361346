import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/api";
import { FiUser, FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import { IoSunnyOutline, IoMoonOutline } from "react-icons/io5";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    setDarkMode(isDarkMode);
    updateTheme(isDarkMode);
  }, []);

  const updateTheme = (isDark) => {
    if (isDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", newDarkMode.toString());
    updateTheme(newDarkMode);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("/api/login/", {
        username,
        password,
      });
      if (response.data && response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("permissions", JSON.stringify(response.data.user.permissions));

        try {
          const companyResponse = await axiosInstance.get("/api/company/");
          if (companyResponse.data && companyResponse.data.length > 0) {
            const companyName = companyResponse.data[0].name;
            localStorage.setItem("companyName", companyName);
          }
        } catch (companyError) {
          console.error("Şirket bilgisi alınamadı:", companyError);
        }

        navigate("/");
      } else {
        throw new Error("Token alınamadı");
      }
    } catch (error) {
      console.error("Giriş başarısız:", error);
      setError(
        "Giriş başarısız. Lütfen bilgilerinizi kontrol edip tekrar deneyin."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 transition-colors duration-300">
      {/* Banner */}
      <div className="md:w-1/2 bg-blue-600 dark:bg-blue-800 p-8 flex items-center justify-center relative overflow-hidden">
        {/* Desen */}
        <div className="absolute inset-0 opacity-10">
          <svg
            className="w-full h-full"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <pattern
              id="appointments"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                width="20"
                height="20"
                fill="none"
                stroke="white"
                strokeWidth="0.5"
              />
              <circle cx="10" cy="10" r="2" fill="white" opacity="0.5" />
            </pattern>
            <rect width="100" height="100" fill="url(#appointments)" />
          </svg>
        </div>

        {/* içerik */}
        <div className="text-center z-10">
          <h1 className="text-3xl md:text-4xl font-bold text-white mb-2">
            SBS Flow
          </h1>
          <p className="text-lg text-blue-100">
            Randevularınızı tek bir yerden kolayca yönetin
          </p>
          <p className="font-bold text-blue-100 bg-blue-400 p-1 rounded-xl dark:bg-blue-500">
            İletişim: +90 539 439 34 44
          </p>
        </div>
      </div>

      {/* login bölüm */}
      <div className="md:w-1/2 flex items-center justify-center p-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-md shadow-xl overflow-hidden p-8 transition-colors duration-300">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-400">
              Giriş Yap
            </h2>
            <button
              onClick={toggleDarkMode}
              className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-blue-100 dark:hover:bg-gray-600 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-200"
              aria-label="Toggle dark mode"
            >
              {darkMode ? (
                <IoSunnyOutline size={18} />
              ) : (
                <IoMoonOutline size={18} />
              )}
            </button>
          </div>
          {error && (
            <p className="text-red-600 dark:text-red-400 text-sm mb-4 bg-red-100 dark:bg-red-900 p-3 rounded-lg">
              {error}
            </p>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
              >
                Kullanıcı Adı
              </label>
              <div className="relative">
                <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
                <input
                  id="username"
                  type="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-600 focus:outline-none focus:border-blue-500 dark:focus:border-blue-400 focus:ring-1 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300"
                  placeholder="Kullanıcı Adınız"
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
              >
                Şifre
              </label>
              <div className="relative">
                <FiLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full pl-10 pr-12 py-2 rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-600 focus:outline-none focus:border-blue-500 dark:focus:border-blue-400 focus:ring-1 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300"
                  placeholder="••••••••"
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none"
                >
                  {showPassword ? <FiEyeOff size={18} /> : <FiEye size={18} />}
                </button>
              </div>
            </div>
            <div className="flex justify-end">
              
               <a href="https://admin.sbsflow.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 transition duration-300"
              >
                Şifremi Unuttum
              </a>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-2 px-4 rounded-lg text-white font-medium ${
                isLoading
                  ? "bg-blue-400 dark:bg-blue-600 cursor-not-allowed"
                  : "bg-blue-600 dark:bg-blue-500 hover:bg-blue-700 dark:hover:bg-blue-600"
              } transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400`}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Giriş Yapılıyor...
                </span>
              ) : (
                "Giriş Yap"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;