import React, { useState, useEffect, useMemo } from "react";
import axiosInstance from "../api/api";
import { format, parseISO, isToday, startOfToday, addDays } from "date-fns";
import { tr } from "date-fns/locale";
import {
  FiCalendar,
  FiClock,
  FiTrash2,
  FiCheck,
  FiChevronDown,
  FiPlus,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import ErrorMessage from "../components/ErrorMessage";

const Appointments = () => {
  const [dateList, setDateList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    startOfToday().toISOString().split("T")[0]
  );
  const [selectedTime, setSelectedTime] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentReservations, setCurrentReservations] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [customerSearch, setCustomerSearch] = useState("");
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isControlAppointment, setIsControlAppointment] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [reservationToComplete, setReservationToComplete] = useState(null);
  const [createNextAppointment, setCreateNextAppointment] = useState(false);
  const [nextAppointmentDays, setNextAppointmentDays] = useState(30);
  const [activeTab, setActiveTab] = useState("normal");
  const [description, setDescription] = useState("");

  useEffect(() => {
    fetchDateList();
    fetchCustomers();
  }, []);

  const fetchDateList = async () => {
    try {
      const response = await axiosInstance.get("/api/reservation/date-list/");
      setDateList(response.data);
    } catch (error) {
      setErrorMessage(
        "Tarih listesi çekme hatası: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/api/customer/");
      setCustomers(response.data);
    } catch (error) {
      setErrorMessage(
        "Müşteri listesi çekme hatası: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    setCurrentReservations([]);
  };

  const handleTimeClick = (time, reservations) => {
    setSelectedTime(time);
    setCurrentReservations(reservations);
  };

  const handleAddReservation = () => {
    setIsModalOpen(true);
    setSelectedCustomer(null);
    setSelectedPackage(null);
    setCustomerSearch("");
    setIsControlAppointment(false);
    setActiveTab("normal");
    setDescription("");
  };

  const handleCustomerSearchChange = (e) => {
    setCustomerSearch(e.target.value);
    setIsCustomerDropdownOpen(true);
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setCustomerSearch(customer.name);
    setIsCustomerDropdownOpen(false);
    if (customer.company_packages_info.length > 0) {
      setSelectedPackage(customer.company_packages_info[0]);
    } else {
      setSelectedPackage(null);
    }
  };

  const handlePackageChange = (e) => {
    const packageId = parseInt(e.target.value);
    const selectedPkg = selectedCustomer.company_packages_info.find(
      (pkg) => pkg.id === packageId
    );
    setSelectedPackage(selectedPkg);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data;
      if (activeTab === "normal") {
        data = {
          reservation_date: selectedDate,
          reservation_time: selectedTime,
          customer: selectedCustomer.id,
          company_package: selectedPackage.id,
          is_control: isControlAppointment,
        };
      } else {
        data = {
          reservation_date: selectedDate,
          reservation_time: selectedTime,
          description: description,
        };
      }

      const response = await axiosInstance.post("/api/reservation/", data);
      setIsModalOpen(false);
      setCurrentReservations((prevReservations) => [
        ...prevReservations,
        response.data,
      ]);
      fetchDateList();
    } catch (error) {
      setErrorMessage(
        "Randevu ekleme hatası: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleDeleteClick = (reservation) => {
    setReservationToDelete(reservation);
    setIsDeleteModalOpen(true);
    setCreateNextAppointment(false);
    setNextAppointmentDays(30);
  };

  const handleCompleteClick = (reservation) => {
    setReservationToComplete(reservation);
    setIsCompleteModalOpen(true);
    setCreateNextAppointment(false);
    setNextAppointmentDays(30);
  };

  const handleComplete = async () => {
    try {
      await axiosInstance.patch(
        `/api/reservation/${reservationToComplete.id}/`,
        { completed: true }
      );

      if (createNextAppointment) {
        const nextDate = addDays(parseISO(selectedDate), nextAppointmentDays);
        await axiosInstance.post("/api/reservation/", {
          reservation_date: format(nextDate, "yyyy-MM-dd"),
          reservation_time: reservationToComplete.reservation_time,
          customer: reservationToComplete.customer_info.id,
          company_package: reservationToComplete.company_package_info.id,
          is_control: reservationToComplete.is_control,
        });
      }

      fetchDateList();
      setCurrentReservations(
        currentReservations.map((r) =>
          r.id === reservationToComplete.id ? { ...r, completed: true } : r
        )
      );
      setIsCompleteModalOpen(false);
    } catch (error) {
      setErrorMessage(
        "Randevu tamamlama hatası: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/api/reservation/${reservationToDelete.id}/`);

      if (createNextAppointment) {
        const nextDate = addDays(parseISO(selectedDate), nextAppointmentDays);
        await axiosInstance.post("/api/reservation/", {
          reservation_date: format(nextDate, "yyyy-MM-dd"),
          reservation_time: reservationToDelete.reservation_time,
          customer: reservationToDelete.customer_info.id,
          company_package: reservationToDelete.company_package_info.id,
          is_control: reservationToDelete.is_control,
        });
      }

      fetchDateList();
      setCurrentReservations(
        currentReservations.filter((r) => r.id !== reservationToDelete.id)
      );
      setIsDeleteModalOpen(false);
    } catch (error) {
      setErrorMessage(
        "Randevu silme hatası: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const filteredCustomers = useMemo(() => {
    return customers.filter((customer) =>
      customer.name.toLowerCase().includes(customerSearch.toLowerCase())
    );
  }, [customers, customerSearch]);

  const sortedDateList = dateList.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  const selectedDateData = dateList.find(
    (dateItem) => dateItem.date === selectedDate
  );

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8 md:mb-10 text-blue-600 dark:text-blue-400">
          Randevular
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8">
          <div className="lg:col-span-1">
            <h3 className="text-xl font-semibold mb-4 text-blue-600 dark:text-blue-400">
              Tarihler
            </h3>
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md space-y-3 max-h-[calc(100vh-300px)] overflow-y-auto">
              {sortedDateList.map((dateItem) => (
                <button
                  key={dateItem.date}
                  onClick={() => handleDateClick(dateItem.date)}
                  className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-200 ${
                    selectedDate === dateItem.date
                      ? "bg-blue-500 dark:bg-blue-600 text-white"
                      : "bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-gray-200"
                  } ${
                    isToday(parseISO(dateItem.date))
                      ? "border-2 border-blue-400 dark:border-blue-500"
                      : ""
                  }`}
                >
                  <div className="flex items-center">
                    <FiCalendar className="mr-3 text-lg" />
                    <span className="text-lg">
                      {format(parseISO(dateItem.date), "dd MMMM yyyy, EEEE", {
                        locale: tr,
                      })}
                    </span>
                  </div>
                  <div className="text-sm mt-2 flex items-center">
                    <FiClock className="mr-2" />
                    {dateItem.time_list.some(
                      (time) => Object.values(time)[0].reservations.length > 0
                    )
                      ? "Bazı saatler dolu"
                      : "Tüm saatler müsait"}
                  </div>
                </button>
              ))}
            </div>
          </div>

          {selectedDateData && (
            <div className="lg:col-span-1">
              <h3 className="text-xl font-semibold mb-4 text-blue-600 dark:text-blue-400">
                Saatler
              </h3>
              <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md grid grid-cols-3 gap-3 max-h-[calc(100vh-300px)] overflow-y-auto">
                {selectedDateData.time_list.map((timeItem) => {
                  const [time, { reservations }] = Object.entries(timeItem)[0];
                  const reservationCount = reservations.length;
                  return (
                    <button
                      key={time}
                      onClick={() => handleTimeClick(time, reservations)}
                      className={`px-3 py-2 rounded-lg transition-colors duration-200 ${
                        reservationCount === 0
                          ? "bg-blue-500 dark:bg-blue-600 hover:bg-blue-600 dark:hover:bg-blue-700 text-white"
                          : reservations.every((r) => r.completed)
                          ? "bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-300"
                          : "bg-yellow-400 dark:bg-yellow-600 hover:bg-yellow-500 dark:hover:bg-yellow-700 text-gray-800 dark:text-gray-200"
                      }`}
                    >
                      <div className="flex items-center justify-center">
                        <FiClock className="mr-2" />
                        <span>{time}</span>
                      </div>
                      {reservationCount > 0 && (
                        <div className="mt-1 text-xs">
                          <p>{reservationCount} randevu</p>
                        </div>
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          )}

          <div className="lg:col-span-1">
            <h3 className="text-xl font-semibold mb-4 text-blue-600 dark:text-blue-400">
              Randevu Detayları
            </h3>
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
              {selectedTime ? (
                <div>
                  <h4 className="font-semibold mb-2">
                    Seçili Saat: {selectedTime}
                  </h4>
                  <button
                    onClick={handleAddReservation}
                    className="mb-4 px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors duration-200 flex items-center"
                  >
                    <FiPlus className="mr-2" />
                    Yeni Randevu Ekle
                  </button>
                  {currentReservations.map((reservation, index) => (
                    <div
                      key={index}
                      className="mb-4 pb-4 border-b border-gray-200 dark:border-gray-700 last:border-b-0"
                    >
                      <p>
                        <strong>Müşteri:</strong>{" "}
                        {reservation.customer_info?.name ||
                          "Günübirlik Müşteri"}
                      </p>
                      {reservation.company_package_info && (
                        <p>
                          <strong>Paket:</strong>{" "}
                          {reservation.company_package_info.name}
                        </p>
                      )}
                      {reservation.description && (
                        <p>
                          <strong>Açıklama:</strong> {reservation.description}
                        </p>
                      )}
                      <p>
                        <strong>Tür:</strong>{" "}
                        {reservation.is_control ? "Kontrol" : "Normal"}
                      </p>
                      <p>
                        <strong>Durum:</strong>{" "}
                        {reservation.completed ? "Tamamlandı" : "Bekliyor"}
                      </p>
                      <div className="mt-2 space-x-2">
                        {!reservation.completed && (
                          <button
                            onClick={() => handleCompleteClick(reservation)}
                            className="px-2 py-1 bg-green-500 dark:bg-green-600 text-white rounded-md hover:bg-green-600 dark:hover:bg-green-700 transition-colors duration-200 text-sm"
                          >
                            <FiCheck className="inline mr-1" />
                            Tamamla
                          </button>
                        )}
                        <button
                          onClick={() => handleDeleteClick(reservation)}
                          className="px-2 py-1 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-700 transition-colors duration-200 text-sm"
                        >
                          <FiTrash2 className="inline mr-1" />
                          Gelmedi
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 dark:text-gray-400">
                  Tarih veya Saat Seçilmedi
                </p>
              )}
            </div>
          </div>
        </div>

        <AnimatePresence>
          {isModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
              >
                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">
                  Yeni Randevu
                </h3>
                <div className="mb-4">
                  <button
                    onClick={() => setActiveTab("normal")}
                    className={`mr-2 px-4 py-2 rounded-md ${
                      activeTab === "normal"
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-800"
                    } hover:${
                      activeTab === "normal" ? "bg-blue-600" : "bg-gray-300"
                    }`}
                  >
                    Normal
                  </button>
                  <button
                    onClick={() => setActiveTab("daily")}
                    className={`px-4 py-2 rounded-md ${
                      activeTab === "daily"
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-800"
                    } hover:${
                      activeTab === "daily" ? "bg-blue-600" : "bg-gray-300"
                    }`}
                  >
                    Günübirlik
                  </button>
                </div>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Tarih ve Saat
                    </label>
                    <div className="text-lg text-gray-800 dark:text-gray-200">
                      {format(parseISO(selectedDate), "dd MMMM yyyy", {
                        locale: tr,
                      })}{" "}
                      - {selectedTime}
                    </div>
                  </div>
                  {activeTab === "normal" ? (
                    <>
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Müşteri
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            value={customerSearch}
                            onChange={handleCustomerSearchChange}
                            onFocus={() => setIsCustomerDropdownOpen(true)}
                            className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="Müşteri adı yazın..."
                            required
                          />
                          <FiChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
                        </div>
                        {isCustomerDropdownOpen && (
                          <ul className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg max-h-60 overflow-auto">
                            {filteredCustomers.map((customer) => (
                              <li
                                key={customer.id}
                                onClick={() => handleCustomerSelect(customer)}
                                className="px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer text-gray-800 dark:text-gray-200"
                              >
                                {customer.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {selectedCustomer && (
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Paket
                          </label>
                          <select
                            value={selectedPackage ? selectedPackage.id : ""}
                            onChange={handlePackageChange}
                            className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                            required
                          >
                            {selectedCustomer.company_packages_info.map(
                              (pkg) => (
                                <option key={pkg.id} value={pkg.id}>
                                  {pkg.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      )}
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="isControlAppointment"
                          checked={isControlAppointment}
                          onChange={(e) =>
                            setIsControlAppointment(e.target.checked)
                          }
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="isControlAppointment"
                          className="ml-2 block text-sm text-gray-900 dark:text-gray-300"
                        >
                          Kontrol Randevusu
                        </label>
                      </div>
                    </>
                  ) : (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Açıklama
                      </label>
                      <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                        rows="4"
                        placeholder="Günübirlik müşteri için açıklama..."
                        required
                      />
                    </div>
                  )}
                  <div className="flex justify-end space-x-3 mt-8">
                    <button
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                      className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors duration-200"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 transition-colors duration-200"
                    >
                      Randevu Ekle
                    </button>
                  </div>
                </form>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isCompleteModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
              >
                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">
                  Randevu Tamamlama
                </h3>
                <p className="mb-4 text-gray-700 dark:text-gray-300">
                  Bu randevuyu tamamlamak istediğinizden emin misiniz?
                </p>
                <div className="mb-4">
                  <label className="flex items-center text-gray-700 dark:text-gray-300">
                    <input
                      type="checkbox"
                      checked={createNextAppointment}
                      onChange={(e) =>
                        setCreateNextAppointment(e.target.checked)
                      }
                      className="mr-2"
                    />
                    Otomatik yeni randevu oluştur
                  </label>
                </div>
                {createNextAppointment && (
                  <div className="mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 mb-2">
                      Kaç gün sonraya randevu verilsin:
                    </label>
                    <input
                      type="number"
                      value={nextAppointmentDays}
                      onChange={(e) =>
                        setNextAppointmentDays(parseInt(e.target.value))
                      }
                      className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600"
                      min="1"
                    />
                  </div>
                )}
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsCompleteModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors duration-200"
                  >
                    İptal
                  </button>
                  <button
                    onClick={handleComplete}
                    className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-green-400 dark:focus:ring-green-500 transition-colors duration-200"
                  >
                    Tamamla
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isDeleteModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
              >
                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">
                  Randevu Silme Onayı
                </h3>
                <p className="mb-4 text-gray-700 dark:text-gray-300">
                  Bu randevuyu silmek istediğinizden emin misiniz?
                </p>
                <div className="mb-4">
                  <label className="flex items-center text-gray-700 dark:text-gray-300">
                    <input
                      type="checkbox"
                      checked={createNextAppointment}
                      onChange={(e) =>
                        setCreateNextAppointment(e.target.checked)
                      }
                      className="mr-2"
                    />
                    Otomatik yeni randevu oluştur
                  </label>
                </div>
                {createNextAppointment && (
                  <div className="mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 mb-2">
                      Kaç gün sonraya randevu verilsin:
                    </label>
                    <input
                      type="number"
                      value={nextAppointmentDays}
                      onChange={(e) =>
                        setNextAppointmentDays(parseInt(e.target.value))
                      }
                      className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600"
                      min="1"
                    />
                  </div>
                )}
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsDeleteModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors duration-200"
                  >
                    İptal
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 dark:focus:ring-red-500 transition-colors duration-200"
                  >
                    Sil
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <ErrorMessage
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      </div>
    </div>
  );
};

export default Appointments;
