import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const closeSidebarOnOutsideClick = (e) => {
      if (isSidebarOpen && e.target.closest('.sidebar') === null && e.target.closest('.hamburger-button') === null) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('click', closeSidebarOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeSidebarOnOutsideClick);
    };
  }, [isSidebarOpen]);

  return (
    <div className="h-screen flex flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <Header toggleSidebar={toggleSidebar} />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-700 p-4 transition-colors duration-200">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;