import React, { useState, useEffect, useMemo, useCallback } from "react";
import axiosInstance from "../api/api";
import {
  FiPackage,
  FiCalendar,
  FiTrendingUp,
  FiTrendingDown,
  FiEdit,
  FiTrash2,
  FiPlus,
  FiSearch,
  FiLoader,
  FiUsers,
} from "react-icons/fi";
import { Bar } from "react-chartjs-2";
import { MdOutlineDiscount } from "react-icons/md";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FaTurkishLiraSign } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";
import ErrorMessage from "../components/ErrorMessage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = () => {
  const [statistics, setStatistics] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [newExpense, setNewExpense] = useState({
    amount: "",
    description: "",
    status: 0,
  });
  const [editingExpenseId, setEditingExpenseId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [totalMonthlyExpense, setTotalMonthlyExpense] = useState(0);
  const [totalMonthlyIncome, setTotalMonthlyIncome] = useState(0);
  const [totalYearlyExpense, setTotalYearlyExpense] = useState(0);
  const [totalYearlyIncome, setTotalYearlyIncome] = useState(0);
  const [todayIncome, setTodayIncome] = useState(0);
  const [todayExpense, setTodayExpense] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [expenseToDelete, setExpenseToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [customerCount, setCustomerCount] = useState(0);
  const [totalReservationsToday, setTotalReservationsToday] = useState(0);
  const [companyExpense, setCompanyExpense] = useState(null);

  const formatDate = (date) => {
    return new Date(date).toISOString().split("T")[0];
  };

  const calculateTodayFinancials = useCallback((expensesList) => {
    const today = formatDate(new Date());
    const todayExpenses = expensesList.filter(
      (expense) => formatDate(expense.expense_date) === today
    );
    const todayInc = todayExpenses.reduce(
      (sum, expense) =>
        expense.status === 1 ? sum + parseFloat(expense.amount) : sum,
      0
    );
    const todayExp = todayExpenses.reduce(
      (sum, expense) =>
        expense.status === 0 ? sum + parseFloat(expense.amount) : sum,
      0
    );
    return { todayIncome: todayInc, todayExpense: todayExp };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [statsResponse, expensesResponse] = await Promise.all([
          axiosInstance.get("/api/statistics/"),
          axiosInstance.get("/api/expense/"),
        ]);
        setStatistics(statsResponse.data);
        const formattedExpenses = expensesResponse.data.map((expense) => ({
          ...expense,
          expense_date: formatDate(expense.expense_date),
        }));
        setExpenses(formattedExpenses);

        setCustomerCount(statsResponse.data.customer_count || 0);
        setTotalReservationsToday(
          statsResponse.data.total_reservations_today || 0
        );
        setCompanyExpense(statsResponse.data.company_expense);

        const currentDate = new Date();
        const currentMonth = formatDate(currentDate).slice(0, 7);
        setStartMonth(currentMonth);
        setEndMonth(currentMonth);
        setSelectedMonth(currentMonth);
        setErrorMessage("");

        const { todayIncome, todayExpense } =
          calculateTodayFinancials(formattedExpenses);
        setTodayIncome(todayIncome);
        setTodayExpense(todayExpense);
      } catch (error) {
        console.error("Data fetching error:", error);
        setErrorMessage(
          "Veriler yüklenirken bir hata oluştu: " +
            error.response?.data?.message || error.message
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [calculateTodayFinancials]);

  useEffect(() => {
    const filteredExpenses = expenses.filter((expense) =>
      expense.expense_date.startsWith(selectedMonth)
    );
    const monthlyExpense = filteredExpenses.reduce(
      (sum, expense) =>
        sum + (expense.status === 0 ? parseFloat(expense.amount) : 0),
      0
    );
    const monthlyIncome = filteredExpenses.reduce(
      (sum, expense) =>
        sum + (expense.status === 1 ? parseFloat(expense.amount) : 0),
      0
    );
    setTotalMonthlyExpense(monthlyExpense);
    setTotalMonthlyIncome(monthlyIncome);

    const currentYear = new Date().getFullYear();
    const yearlyExpenses = expenses.filter(
      (expense) => new Date(expense.expense_date).getFullYear() === currentYear
    );
    const yearlyExpense = yearlyExpenses.reduce(
      (sum, expense) =>
        sum + (expense.status === 0 ? parseFloat(expense.amount) : 0),
      0
    );
    const yearlyIncome = yearlyExpenses.reduce(
      (sum, expense) =>
        sum + (expense.status === 1 ? parseFloat(expense.amount) : 0),
      0
    );
    setTotalYearlyExpense(yearlyExpense);
    setTotalYearlyIncome(yearlyIncome);
  }, [expenses, selectedMonth]);

  const handleExpenseSubmit = async (e) => {
    e.preventDefault();
    try {
      const expenseData = {
        ...newExpense,
        expense_date: formatDate(new Date()),
      };
      if (editingExpenseId) {
        await axiosInstance.put(
          `/api/expense/${editingExpenseId}/`,
          expenseData
        );
      } else {
        await axiosInstance.post("/api/expense/", expenseData);
      }
      setIsModalOpen(false);
      const response = await axiosInstance.get("/api/expense/");
      const formattedExpenses = response.data.map((expense) => ({
        ...expense,
        expense_date: formatDate(expense.expense_date),
      }));
      setExpenses(formattedExpenses);
      const { todayIncome, todayExpense } =
        calculateTodayFinancials(formattedExpenses);
      setTodayIncome(todayIncome);
      setTodayExpense(todayExpense);
      setNewExpense({ amount: "", description: "", status: 0 });
      setEditingExpenseId(null);
    } catch (error) {
      console.error("Expense operation error:", error);
      setErrorMessage(
        "Harcama işlemi sırasında bir hata oluştu: " +
          error.response?.data?.message || error.message
      );
    }
  };

  const handleExpenseEdit = (expense) => {
    setNewExpense({
      amount: expense.amount,
      description: expense.description,
      status: expense.status,
    });
    setEditingExpenseId(expense.id);
    setIsModalOpen(true);
  };

  const handleExpenseDeleteClick = (expense) => {
    setExpenseToDelete(expense);
    setIsDeleteModalOpen(true);
  };

  const handleExpenseDelete = async () => {
    if (!expenseToDelete) return;
    try {
      await axiosInstance.delete(`/api/expense/${expenseToDelete.id}/`);
      const response = await axiosInstance.get("/api/expense/");
      const formattedExpenses = response.data.map((expense) => ({
        ...expense,
        expense_date: formatDate(expense.expense_date),
      }));
      setExpenses(formattedExpenses);
      const { todayIncome, todayExpense } =
        calculateTodayFinancials(formattedExpenses);
      setTodayIncome(todayIncome);
      setTodayExpense(todayExpense);
      setIsDeleteModalOpen(false);
      setExpenseToDelete(null);
    } catch (error) {
      console.error("Expense deletion error:", error);
      setErrorMessage(
        "Harcama silinirken bir hata oluştu: " +
          error.response?.data?.message || error.message
      );
    }
  };

  const filteredData = useMemo(() => {
    if (!statistics || !statistics.monthly_profit_loss) return [];
    return statistics.monthly_profit_loss
      .filter((item) => item.month >= startMonth && item.month <= endMonth)
      .reverse();
  }, [statistics, startMonth, endMonth]);

  const chartData = {
    labels: filteredData.map((item) => item.month),
    datasets: [
      {
        label: "Kar/Zarar",
        data: filteredData.map((item) => item.profit_or_loss),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Toplam Ödemeler",
        data: filteredData.map((item) => item.total_payments),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Toplam Borç",
        data: filteredData.map((item) => item.total_debt),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Aylık Finansal Görünüm",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const StatCard = ({ icon: Icon, title, value, color }) => (
    <div
      className={`bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md border-l-4 ${color}`}
    >
      <div className="flex items-center">
        <Icon className="text-2xl mr-3" />
        <div>
          <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-300">
            {title}
          </h3>
          <p className="text-lg font-bold text-gray-900 dark:text-white">
            {value}
          </p>
        </div>
      </div>
    </div>
  );

  const filteredExpenses = useMemo(
    () =>
      expenses.filter(
        (expense) =>
          expense.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) &&
          expense.expense_date.startsWith(selectedMonth)
      ),
    [expenses, searchTerm, selectedMonth]
  );

  if (loading)
    return (
      <div className="flex justify-center items-center h-64">
        <FiLoader className="animate-spin text-blue-500 text-4xl" />
        <span className="ml-2 text-lg">Sayfa yükleniyor...</span>
      </div>
    );
  if (!statistics)
    return (
      <div className="flex justify-center items-center h-screen">
        Veri bulunamadı.
      </div>
    );

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-800 dark:text-white">
          İstatistikler
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
          <StatCard
            icon={FiPackage}
            title="Toplam Paket"
            value={statistics.package_count || 0}
            color="border-blue-500"
          />
          <StatCard
            icon={MdOutlineDiscount}
            title="Toplam Müşteri İndirim"
            value={`₺${(statistics.total_discount || 0).toLocaleString()}`}
            color="border-green-500"
          />
          <StatCard
            icon={FiCalendar}
            title="Toplam Randevu"
            value={statistics.total_reservations || 0}
            color="border-yellow-500"
          />
          <StatCard
            icon={FaTurkishLiraSign}
            title="Bugünkü Toplam Ödemeler"
            value={`₺${(statistics.total_payment_today || 0).toLocaleString()}`}
            color="border-purple-500"
          />
          <StatCard
            icon={FaTurkishLiraSign}
            title="Toplam Müşteri Borç"
            value={`₺${(statistics.total_debt || 0).toLocaleString()}`}
            color="border-red-500"
          />
          <StatCard
            icon={FaTurkishLiraSign}
            title="Toplam Müşteri Ödeme"
            value={`₺${(statistics.total_payment || 0).toLocaleString()}`}
            color="border-green-500"
          />
          <StatCard
            icon={FiUsers}
            title="Toplam Müşteri"
            value={customerCount}
            color="border-indigo-500"
          />
          <StatCard
            icon={FiCalendar}
            title="Bugünkü Randevular"
            value={totalReservationsToday}
            color="border-pink-500"
          />
          {companyExpense !== null && (
            <StatCard
              icon={FaTurkishLiraSign}
              title="Şirket Gideri"
              value={`₺${companyExpense.toLocaleString()}`}
              color="border-orange-500"
            />
          )}
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
            Aylık Finansal Özet
          </h2>
          <div className="flex flex-wrap gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Başlangıç Ayı
              </label>
              <input
                type="month"
                value={startMonth}
                onChange={(e) => setStartMonth(e.target.value)}
                className="p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Bitiş Ayı
              </label>
              <input
                type="month"
                value={endMonth}
                onChange={(e) => setEndMonth(e.target.value)}
                className="p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
          </div>
          <div style={{ height: "400px" }}>
            {filteredData.length > 0 ? (
              <Bar data={chartData} options={chartOptions} />
            ) : (
              <div className="flex justify-center items-center h-full text-gray-500">
                Seçilen tarih aralığında veri bulunmamaktadır.
              </div>
            )}
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-md overflow-x-auto mb-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
            Detaylı Aylık Veriler
          </h2>
          {filteredData.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Ay
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Kar/Zarar
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Toplam Müşteri Ödemeler
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Toplam Müşteri Borç
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Toplam Müşteri İndirimler
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {filteredData.map((item, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? "bg-gray-50 dark:bg-gray-700"
                        : "bg-white dark:bg-gray-800"
                    }
                  >
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      {item.month}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      <span
                        className={
                          item.profit_or_loss >= 0
                            ? "text-green-600"
                            : "text-red-600"
                        }
                      >
                        {item.profit_or_loss >= 0 ? (
                          <FiTrendingUp className="inline mr-1" />
                        ) : (
                          <FiTrendingDown className="inline mr-1" />
                        )}
                        ₺{item.profit_or_loss.toLocaleString()}
                      </span>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      ₺{item.total_payments.toLocaleString()}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      ₺{item.total_debt.toLocaleString()}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      ₺{item.total_discounts.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center py-4 text-gray-500">
              Seçilen tarih aralığında veri bulunmamaktadır.
            </div>
          )}
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
            Harcamalar ve Gelirler
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4 mb-6">
            <div className="bg-blue-100 dark:bg-blue-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-blue-800 dark:text-blue-200 mb-2">
                Aylık Gider
              </h3>
              <p className="text-2xl font-bold text-blue-600 dark:text-blue-300">
                ₺{totalMonthlyExpense.toLocaleString()}
              </p>
            </div>
            <div className="bg-green-100 dark:bg-green-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-green-800 dark:text-green-200 mb-2">
                Aylık Gelir
              </h3>
              <p className="text-2xl font-bold text-green-600 dark:text-green-300">
                ₺{totalMonthlyIncome.toLocaleString()}
              </p>
            </div>
            <div className="bg-red-100 dark:bg-red-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-red-800 dark:text-red-200 mb-2">
                Yıllık Gider
              </h3>
              <p className="text-2xl font-bold text-red-600 dark:text-red-300">
                ₺{totalYearlyExpense.toLocaleString()}
              </p>
            </div>
            <div className="bg-yellow-100 dark:bg-yellow-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-yellow-800 dark:text-yellow-200 mb-2">
                Yıllık Gelir
              </h3>
              <p className="text-2xl font-bold text-yellow-600 dark:text-yellow-300">
                ₺{totalYearlyIncome.toLocaleString()}
              </p>
            </div>
            <div className="bg-purple-100 dark:bg-purple-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-purple-800 dark:text-purple-200 mb-2">
                Bugünkü Gider
              </h3>
              <p className="text-2xl font-bold text-purple-600 dark:text-purple-300">
                ₺{todayExpense.toLocaleString()}
              </p>
            </div>
            <div className="bg-indigo-100 dark:bg-indigo-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-indigo-800 dark:text-indigo-200 mb-2">
                Bugünkü Gelir
              </h3>
              <p className="text-2xl font-bold text-indigo-600 dark:text-indigo-300">
                ₺{todayIncome.toLocaleString()}
              </p>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
            <button
              onClick={() => {
                setNewExpense({
                  amount: "",
                  description: "",
                  status: 0,
                });
                setEditingExpenseId(null);
                setIsModalOpen(true);
              }}
              className="mb-4 sm:mb-0 px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors duration-200 flex items-center"
            >
              <FiPlus className="mr-2" />
              Yeni Ekle
            </button>
            <div className="flex items-center space-x-4">
              <div className="relative">
                <input
                  type="month"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>
              <div className="relative w-full sm:w-64">
                <input
                  type="text"
                  placeholder="Ara..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full px-4 py-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-500"
                />
                <FiSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Tarih
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Miktar
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Açıklama
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Tür
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {filteredExpenses.map((expense) => (
                  <tr
                    key={expense.id}
                    className="hover:bg-blue-50 dark:hover:bg-gray-700 transition-colors duration-200"
                  >
                    <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">
                      {new Date(expense.expense_date).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">
                      ₺{parseFloat(expense.amount).toLocaleString()}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">
                      {expense.description}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">
                      {expense.status === 0 ? "Gider" : "Gelir"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => handleExpenseEdit(expense)}
                        className="bg-green-100 dark:bg-green-800 text-green-600 dark:text-green-200 hover:bg-green-200 dark:hover:bg-green-700 rounded-full p-2 transition duration-300 mr-3"
                      >
                        <FiEdit />
                      </button>
                      <button
                        onClick={() => handleExpenseDeleteClick(expense)}
                        className="bg-red-100 dark:bg-red-800 text-red-600 dark:text-red-200 hover:bg-red-200 dark:hover:bg-red-700 rounded-full p-2 transition duration-300"
                      >
                        <FiTrash2 />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {filteredExpenses.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              Seçilen ayda kayıt bulunmamaktadır.
            </div>
          )}
        </div>

        <AnimatePresence>
          {isModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
              >
                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">
                  {editingExpenseId ? "Düzenle" : "Yeni Ekle"}
                </h3>
                <form onSubmit={handleExpenseSubmit} className="space-y-4">
                  <div className="flex space-x-4 mb-4">
                    <button
                      type="button"
                      className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                        newExpense.status === 0
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-blue-100"
                      }`}
                      onClick={() =>
                        setNewExpense({ ...newExpense, status: 0 })
                      }
                    >
                      Gider
                    </button>
                    <button
                      type="button"
                      className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                        newExpense.status === 1
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-green-100"
                      }`}
                      onClick={() =>
                        setNewExpense({ ...newExpense, status: 1 })
                      }
                    >
                      Gelir
                    </button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Miktar
                    </label>
                    <input
                      type="number"
                      name="amount"
                      value={newExpense.amount}
                      onChange={(e) =>
                        setNewExpense({ ...newExpense, amount: e.target.value })
                      }
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Açıklama
                    </label>
                    <input
                      type="text"
                      name="description"
                      value={newExpense.description}
                      onChange={(e) =>
                        setNewExpense({
                          ...newExpense,
                          description: e.target.value,
                        })
                      }
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <div className="flex justify-end space-x-3 mt-6">
                    <button
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                      className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors duration-200"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 transition-colors duration-200"
                    >
                      {editingExpenseId ? "Güncelle" : "Ekle"}
                    </button>
                  </div>
                </form>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isDeleteModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
              >
                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">
                  Silme Onayı
                </h3>
                <p className="mb-6 text-gray-700 dark:text-gray-300">
                  Bu kaydı silmek istediğinizden emin misiniz?
                </p>
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsDeleteModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:gray-500 transition-colors duration-200"
                  >
                    İptal
                  </button>
                  <button
                    onClick={handleExpenseDelete}
                    className="px-4 py-2 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 dark:focus:ring-red-500 transition-colors duration-200"
                  >
                    Sil
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <ErrorMessage
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      </div>
    </div>
  );
};

export default Statistics;
