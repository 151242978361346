import React from 'react';
import { motion } from 'framer-motion';

const SaleModal = ({ isOpen, onClose, saleInfo, products, productSearchTerm, setProductSearchTerm, handleProductChange, handleSaleSubmit }) => {
  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white dark:bg-gray-800 p-6 md:p-8 rounded-lg shadow-xl w-full max-w-md mx-auto"
      >
        <h3 className="text-2xl font-bold mb-6 text-blue-600 dark:text-blue-400">Ürün Satışı - {saleInfo.customer.name}</h3>
        <div className="mb-6">
          <h4 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Ürünler</h4>
          <div className="mb-2">
            <input
              type="text"
              placeholder="Ürün ara..."
              value={productSearchTerm}
              onChange={(e) => setProductSearchTerm(e.target.value)}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
            />
          </div>
          <div className="space-y-2 max-h-60 overflow-y-auto pr-2 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600">
            {products
              .filter(product => product.name.toLowerCase().includes(productSearchTerm.toLowerCase()))
              .map(product => (
                <label key={product.id} className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-600">
                  <div className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      value={product.id}
                      checked={saleInfo.products.includes(product.id)}
                      onChange={handleProductChange}
                      className="form-checkbox h-5 w-5 text-blue-600 dark:text-blue-400 rounded focus:ring-blue-400 dark:focus:ring-blue-600 transition duration-150 ease-in-out"
                    />
                    <span className="text-gray-700 dark:text-gray-300">{product.name}</span>
                  </div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Stok: {product.stock}
                  </span>
                </label>
              ))}
          </div>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition duration-150 ease-in-out"
          >
            İptal
          </button>
          <button
            onClick={handleSaleSubmit}
            className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 transition duration-150 ease-in-out"
          >
            Satışı Tamamla
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SaleModal;