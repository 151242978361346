import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../api/api";
import {
  FiClock,
  FiUser,
  FiCheck,
  FiPhone,
  FiSearch,
  FiLoader,
  FiXCircle,
  FiInfo,
} from "react-icons/fi";
import { format, addDays, parseISO, isToday, isTomorrow } from "date-fns";
import { motion, AnimatePresence } from "framer-motion";
import ErrorMessage from "../components/ErrorMessage";

const Home = () => {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [todayAppointments, setTodayAppointments] = useState([]);
  const [tomorrowAppointments, setTomorrowAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [createNextAppointment, setCreateNextAppointment] = useState(false);
  const [nextAppointmentDays, setNextAppointmentDays] = useState(30);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [actionType, setActionType] = useState("complete");
  const [notification, setNotification] = useState({
    message: "",
    type: "error",
  });
  const [activeTab, setActiveTab] = useState("today");

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const [companyResponse, appointmentsResponse] = await Promise.all([
        axiosInstance.get("/api/company/"),
        axiosInstance.get("/api/reservation/"),
      ]);

      setCompanyInfo(companyResponse.data[0] || null);

      const todayAppts = appointmentsResponse.data.filter(
        (appt) => isToday(parseISO(appt.reservation_date)) && !appt.completed
      );
      const tomorrowAppts = appointmentsResponse.data.filter(
        (appt) => isTomorrow(parseISO(appt.reservation_date)) && !appt.completed
      );

      setTodayAppointments(todayAppts);
      setTomorrowAppointments(tomorrowAppts);
      setFilteredAppointments(
        activeTab === "today" ? todayAppts : tomorrowAppts
      );
    } catch (error) {
      console.error("Veri çekme hatası:", error);
      setNotification({
        message:
          "Veri çekme sırasında bir hata oluştu. Lütfen sayfayı yenileyin.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const appointments =
      activeTab === "today" ? todayAppointments : tomorrowAppointments;
    const filtered = appointments.filter(
      (appointment) =>
        appointment.customer_info?.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        appointment.description
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredAppointments(filtered);
  }, [searchTerm, todayAppointments, tomorrowAppointments, activeTab]);

  const handleActionClick = useCallback((appointment, type) => {
    setSelectedAppointment(appointment);
    setActionType(type);
    setIsModalOpen(true);
  }, []);

  const handleAppointmentAction = useCallback(async () => {
    try {
      if (actionType === "complete") {
        await axiosInstance.patch(
          `/api/reservation/${selectedAppointment.id}/`,
          { completed: true }
        );
      } else if (actionType === "noshow") {
        await axiosInstance.delete(
          `/api/reservation/${selectedAppointment.id}/`
        );
      }

      if (createNextAppointment && selectedAppointment.customer_info) {
        const nextDate = addDays(
          parseISO(selectedAppointment.reservation_date),
          nextAppointmentDays
        );
        await axiosInstance.post("/api/reservation/", {
          reservation_date: format(nextDate, "yyyy-MM-dd"),
          reservation_time: selectedAppointment.reservation_time,
          customer: selectedAppointment.customer_info.id,
          company_package: selectedAppointment.company_package_info?.id,
          is_control: selectedAppointment.is_control,
          description: selectedAppointment.description,
        });
      }

      setTodayAppointments((prev) =>
        prev.filter((appt) => appt.id !== selectedAppointment.id)
      );
      setTomorrowAppointments((prev) =>
        prev.filter((appt) => appt.id !== selectedAppointment.id)
      );
      setFilteredAppointments((prev) =>
        prev.filter((appt) => appt.id !== selectedAppointment.id)
      );
      setIsModalOpen(false);
      setSelectedAppointment(null);
      setCreateNextAppointment(false);
      setNextAppointmentDays(30);
      setActionType("complete");
      setNotification({
        message: "İşlem başarıyla tamamlandı.",
        type: "success",
      });
    } catch (error) {
      console.error("Randevu işlemi hatası:", error);
      setNotification({
        message:
          "Randevu işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.",
        type: "error",
      });
    }
  }, [
    selectedAppointment,
    createNextAppointment,
    nextAppointmentDays,
    actionType,
  ]);

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8 md:mb-10 text-blue-600 dark:text-blue-400">
          Kontrol Paneli
        </h2>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <FiLoader className="animate-spin text-blue-500 text-4xl" />
            <span className="ml-2 text-lg">Sayfa yükleniyor...</span>
          </div>
        ) : (
          <>
            {companyInfo && (
              <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg mb-6 sm:mb-8 shadow-md">
                <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-blue-600 dark:text-blue-400 mb-2">
                  {companyInfo.name}
                </h3>
                <p className="text-gray-600 dark:text-gray-400 text-sm sm:text-base">
                  {companyInfo.address}
                </p>
                <p className="text-gray-600 dark:text-gray-400 text-sm sm:text-base">
                  {companyInfo.phone}
                </p>
              </div>
            )}

            <div className="mb-6">
              <div className="flex border-b border-gray-200 dark:border-gray-700">
                <button
                  className={`py-2 px-4 font-medium transition-colors duration-200 ${
                    activeTab === "today"
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : "text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                  }`}
                  onClick={() => setActiveTab("today")}
                >
                  Bugünkü Randevular
                </button>
                <button
                  className={`py-2 px-4 font-medium transition-colors duration-200 ${
                    activeTab === "tomorrow"
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : "text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400"
                  }`}
                  onClick={() => setActiveTab("tomorrow")}
                >
                  Yarınki Randevular
                </button>
              </div>
            </div>

            <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold mb-4 sm:mb-6 text-blue-600 dark:text-blue-400">
              {activeTab === "today" ? "Bugünkü" : "Yarınki"} Bekleyen
              Randevular
            </h3>

            <div className="mb-4 relative">
              <input
                type="text"
                placeholder="Müşteri Adı veya Açıklama ile Ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 pl-10 pr-4 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>

            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full min-w-max table-auto">
                  <thead className="bg-blue-50 dark:bg-gray-700 sticky top-0">
                    <tr>
                      {[
                        "Saat",
                        "Müşteri/Açıklama",
                        "Telefon",
                        "Paket",
                        "Tür",
                        "İşlemler",
                      ].map((header, index) => (
                        <th
                          key={index}
                          className="px-4 py-3 text-left text-xs font-medium text-blue-600 dark:text-blue-400 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {filteredAppointments.length > 0 ? (
                      filteredAppointments.map((appointment) => (
                        <tr
                          key={appointment.id}
                          className="hover:bg-blue-50 dark:hover:bg-gray-700 transition-colors duration-200"
                        >
                          <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base">
                            <FiClock className="inline mr-2 text-blue-400" />
                            {appointment.reservation_time}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              {appointment.customer_info ? (
                                <>
                                  <FiUser className="mr-2 text-blue-400" />
                                  <span className="text-sm sm:text-base">
                                    {appointment.customer_info.name}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <FiInfo className="mr-2 text-blue-400" />
                                  <span className="text-sm sm:text-base">
                                    {appointment.description ||
                                      "Günübirlik Randevu"}
                                  </span>
                                </>
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <FiPhone className="mr-2 text-blue-400" />
                              <span className="text-sm sm:text-base">
                                {appointment.customer_info?.phone || "-"}
                              </span>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            {appointment.company_package_info ? (
                              <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200">
                                {appointment.company_package_info.name}
                              </span>
                            ) : (
                              <span className="text-gray-500 dark:text-gray-400">
                                -
                              </span>
                            )}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <span
                              className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                appointment.customer_info
                                  ? appointment.is_control
                                    ? "bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200"
                                    : "bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200"
                                  : "bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200"
                              }`}
                            >
                              {appointment.customer_info
                                ? appointment.is_control
                                  ? "Kontrol"
                                  : "Normal"
                                : "Günübirlik"}
                            </span>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-medium">
                            <div className="flex space-x-2">
                              <button
                                onClick={() =>
                                  handleActionClick(appointment, "complete")
                                }
                                className="px-3 py-1 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors duration-200 flex items-center"
                              >
                                <FiCheck className="mr-1" />
                                Tamamla
                              </button>
                              <button
                                onClick={() =>
                                  handleActionClick(appointment, "noshow")
                                }
                                className="px-3 py-1 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-700 transition-colors duration-200 flex items-center"
                              >
                                <FiXCircle className="mr-1" />
                                Gelmedi
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="px-4 py-3 text-center text-sm sm:text-base"
                        >
                          {searchTerm
                            ? "Aranan kriterlere uygun randevu bulunamadı."
                            : `${
                                activeTab === "today" ? "Bugün" : "Yarın"
                              } için bekleyen randevu bulunmamaktadır.`}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        <AnimatePresence>
          {isModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-md"
              >
                <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
                  {actionType === "complete"
                    ? "Randevu Tamamlama"
                    : "Randevuya Gelmedi"}
                </h3>
                <p className="mb-4 text-gray-600 dark:text-gray-400">
                  {actionType === "complete"
                    ? "Bu randevuyu tamamlamak istediğinizden emin misiniz?"
                    : "Bu randevuyu iptal etmek istediğinizden emin misiniz?"}
                </p>
                {selectedAppointment && selectedAppointment.customer_info && (
                  <div className="mb-4">
                    <label className="flex items-center text-gray-700 dark:text-gray-300">
                      <input
                        type="checkbox"
                        checked={createNextAppointment}
                        onChange={(e) =>
                          setCreateNextAppointment(e.target.checked)
                        }
                        className="mr-2"
                      />
                      Otomatik yeni randevu oluştur
                    </label>
                  </div>
                )}
                {createNextAppointment &&
                  selectedAppointment &&
                  selectedAppointment.customer_info && (
                    <div className="mb-4">
                      <label className="block text-gray-700 dark:text-gray-300 mb-2">
                        Kaç gün sonraya randevu verilsin:
                      </label>
                      <input
                        type="number"
                        value={nextAppointmentDays}
                        onChange={(e) =>
                          setNextAppointmentDays(parseInt(e.target.value))
                        }
                        className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600"
                        min="1"
                      />
                    </div>
                  )}
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600"
                  >
                    İptal
                  </button>
                  <button
                    onClick={handleAppointmentAction}
                    className={`px-4 py-2 text-white rounded-md ${
                      actionType === "complete"
                        ? "bg-blue-500 hover:bg-blue-600"
                        : "bg-red-500 hover:bg-red-600"
                    }`}
                  >
                    {actionType === "complete"
                      ? "Tamamla"
                      : "Gelmedi Olarak İşaretle"}
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <ErrorMessage
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ message: "", type: "error" })}
        />
      </div>
    </div>
  );
};

export default Home;