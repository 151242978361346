import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FiPlus,
  FiMinus,
  FiArrowLeft,
  FiChevronDown,
  FiChevronUp,
  FiSave,
  FiCreditCard,
} from "react-icons/fi";
import { FaLiraSign } from "react-icons/fa";
import axiosInstance from "../../api/api";
import ErrorMessage from "../ErrorMessage";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full"
          >
            <p className="text-gray-700 dark:text-gray-300 mb-4">{message}</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-300 dark:hover:bg-gray-500 transition duration-150 ease-in-out text-sm"
              >
                Hayır
              </button>
              <button
                onClick={onConfirm}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-150 ease-in-out text-sm"
              >
                Evet
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const CustomerModal = ({
  isOpen,
  onClose,
  selectedCustomer,
  packages,
  packageSearchTerm,
  setPackageSearchTerm,
  handleInputChange,
  handlePackageChange,
  handleSubmit,
}) => {
  const [activeTab, setActiveTab] = useState("info");
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [installments, setInstallments] = useState([]);
  const [bulkInstallment, setBulkInstallment] = useState({
    installment_date: "",
    installment_count: 1,
  });
  const [showPaymentList, setShowPaymentList] = useState(true);
  const [expandedInstallments, setExpandedInstallments] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const fetchCustomerData = useCallback(async () => {
    if (!selectedCustomer || !selectedCustomer.id) return;
    try {
      const response = await axiosInstance.get(
        `/api/customer/${selectedCustomer.id}/`
      );
      const customerData = response.data;
      setPayments(customerData.payments || []);
    } catch (error) {
      setErrorMessage("Müşteri verilerini getirirken bir hata oluştu.");
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (isOpen) {
      setActiveTab("info");
      setShowPaymentList(true);
      setSelectedPayment(null);

      setSuccessMessage("");
      setErrorMessage("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.id) {
      fetchCustomerData();
    } else {
      setPayments([]);
      setInstallments([]);
    }
  }, [selectedCustomer, fetchCustomerData]);

  const handleInstallmentChange = (index, field, value) => {
    const updatedInstallments = [...installments];

    if (field === "payed_amount") {
      const maxPayableAmount = parseFloat(updatedInstallments[index].amount);

      if (parseFloat(value) > maxPayableAmount) {
        setErrorMessage("Ödenen tutar, taksit tutarını aşamaz.");
        value = maxPayableAmount;
      }
    }

    if (field === "amount") {
      const newAmount = parseFloat(value);
      const currentPayedAmount = parseFloat(
        updatedInstallments[index].payed_amount
      );

      if (currentPayedAmount > newAmount) {
        setErrorMessage(
          "Ödenen tutar, yeni taksit tutarını aşamaz ve yeni taksit tutarına ayarlandı."
        );
        updatedInstallments[index].payed_amount = newAmount;
      }
    }

    updatedInstallments[index] = {
      ...updatedInstallments[index],
      [field]: value,
    };

    setInstallments(updatedInstallments);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchInstallments = async (paymentId) => {
    try {
      const response = await axiosInstance.get(`/api/payment/${paymentId}/`);
      setSelectedPayment(response.data);
      setInstallments(response.data.installments || []);
    } catch (error) {
      setErrorMessage("Taksit bilgilerini getirirken bir hata oluştu.");
    }
  };

  const handlePaymentClick = async (payment) => {
    setSelectedPayment(payment);
    await fetchInstallments(payment.id);
    setShowPaymentList(false);
  };

  const handlePaymentInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedPayment((prev) => ({ ...prev, [name]: value }));
  };

  const addInstallment = async () => {
    try {
      const newInstallment = {
        amount: 0,
        payed_amount: "",
        payment_date: new Date().toISOString().split("T")[0],
        description: "",
        payment: selectedPayment.id,
      };
      const response = await axiosInstance.post(
        "/api/installment/",
        newInstallment
      );
      setInstallments([...installments, response.data]);
      setSuccessMessage("Yeni taksit başarıyla eklendi.");
    } catch (error) {
      setErrorMessage("Taksit eklerken bir hata oluştu.");
    }
  };

  const removeInstallment = async (index) => {
    setConfirmationMessage("Bu taksiti silmek istediğinizden emin misiniz?");
    setConfirmationAction(() => async () => {
      try {
        const installmentToRemove = installments[index];
        if (installmentToRemove.id) {
          await axiosInstance.delete(
            `/api/installment/${installmentToRemove.id}/`
          );
        }
        const updatedInstallments = installments.filter((_, i) => i !== index);
        setInstallments(updatedInstallments);
        setShowConfirmation(false);
        setSuccessMessage("Taksit başarıyla silindi.");
      } catch (error) {
        setErrorMessage("Taksit silinirken bir hata oluştu.");
      }
    });
    setShowConfirmation(true);
  };

  const handleBulkInstallmentChange = (e) => {
    const { name, value } = e.target;
    setBulkInstallment((prev) => ({
      ...prev,
      [name]: name === "installment_count" ? parseInt(value, 10) : value,
    }));
  };

  const createBulkInstallments = async () => {
    try {
      await axiosInstance.post("/api/installment/bulk_create/", {
        ...bulkInstallment,
        payment: selectedPayment.id,
        installment_count: parseInt(bulkInstallment.installment_count, 10),
      });
      await fetchInstallments(selectedPayment.id);
      setSuccessMessage("Toplu taksitler başarıyla oluşturuldu.");
    } catch (error) {
      setErrorMessage("Toplu taksit oluştururken bir hata oluştu.");
    }
  };

  const savePaymentAndInstallments = async () => {
    if (isSaveButtonDisabled) return;

    setIsSaveButtonDisabled(true);
    try {
      await axiosInstance.patch(
        `/api/payment/${selectedPayment.id}/`,
        selectedPayment
      );
      for (const installment of installments) {
        if (installment.id) {
          await axiosInstance.patch(`/api/installment/${installment.id}/`, {
            amount: installment.amount,
            payed_amount: installment.payed_amount,
            payment_date: installment.payment_date,
            description: installment.description,
          });
        } else {
          await axiosInstance.post("/api/installment/", {
            ...installment,
            payment: selectedPayment.id,
          });
        }
      }
      await fetchCustomerData();
      await fetchInstallments(selectedPayment.id);
      setShowPaymentList(true);
      setSuccessMessage("Ödeme ve taksitler başarıyla kaydedildi.");
    } catch (error) {
      setErrorMessage("Ödeme ve taksitler kaydedilirken bir hata oluştu.");
    }

    setTimeout(() => {
      setIsSaveButtonDisabled(false);
    }, 5000);
  };

  const addNewPayment = async () => {
    try {
      const newPayment = {
        amount: "0",
        payed_amount: "0",
        payment_type: "cash",
        discount: "0",
        description: "",
        customer: selectedCustomer.id,
      };
      const response = await axiosInstance.post("/api/payment/", newPayment);
      await fetchCustomerData();
      setSelectedPayment(response.data);
      setInstallments([]);
      setShowPaymentList(false);
      setSuccessMessage("Yeni ödeme başarıyla eklendi.");
    } catch (error) {
      setErrorMessage("Yeni ödeme eklenirken bir hata oluştu.");
    }
  };

  const deletePayment = async (paymentId) => {
    setConfirmationMessage("Bu ödemeyi silmek istediğinizden emin misiniz?");
    setConfirmationAction(() => async () => {
      try {
        await axiosInstance.delete(`/api/payment/${paymentId}/`);
        await fetchCustomerData();
        setSelectedPayment(null);
        setInstallments([]);
        setShowPaymentList(true);
        setShowConfirmation(false);
        setSuccessMessage("Ödeme başarıyla silindi.");
      } catch (error) {
        setErrorMessage("Ödeme silinirken bir hata oluştu.");
      }
    });
    setShowConfirmation(true);
  };

  const toggleInstallmentAccordion = () => {
    setExpandedInstallments(!expandedInstallments);
  };

  if (!isOpen || !selectedCustomer) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white dark:bg-gray-800 p-6 md:p-8 rounded-lg shadow-xl w-full max-w-7xl mx-auto max-h-[90vh] overflow-y-auto"
          >
            <h3 className="text-2xl font-bold mb-6 text-blue-600 dark:text-blue-400 flex items-center">
              {selectedCustomer && selectedCustomer.id ? (
                <>
                  <span>Müşteri Düzenle:</span>
                  <span className="ml-2 text-gray-700 dark:text-gray-300">
                    {selectedCustomer.name}
                  </span>
                </>
              ) : (
                "Yeni Müşteri Ekle"
              )}
            </h3>
            <div className="mb-6">
              <div className="flex border-b border-gray-200 dark:border-gray-700">
                <button
                  className={`py-2 px-4 ${
                    activeTab === "info"
                      ? "border-b-2 border-blue-500 text-blue-500"
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  }`}
                  onClick={() => setActiveTab("info")}
                >
                  Müşteri Bilgileri
                </button>
                <button
                  className={`py-2 px-4 ${
                    activeTab === "packages"
                      ? "border-b-2 border-blue-500 text-blue-500"
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  }`}
                  onClick={() => setActiveTab("packages")}
                >
                  Paketler
                </button>
                <button
                  className={`py-2 px-4 ${
                    activeTab === "payments"
                      ? "border-b-2 border-blue-500 text-blue-500"
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  }`}
                  onClick={() => setActiveTab("payments")}
                >
                  Ödemeler ve Taksitler
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit} className="space-y-5">
              {activeTab === "info" && (
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      İsim
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={selectedCustomer.name || ""}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      TC Kimlik Numarası
                    </label>
                    <input
                      type="number"
                      name="identify_number"
                      value={selectedCustomer.identify_number || ""}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Telefon
                    </label>
                    <div className="flex">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 text-gray-500 dark:text-gray-400 text-sm">
                        +90
                      </span>
                      <input
                        type="tel"
                        name="phone"
                        value={(selectedCustomer.phone || "").replace(
                          /^\+90/,
                          ""
                        )}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-r-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      E-posta
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={selectedCustomer.email || ""}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Not
                    </label>
                    <textarea
                      name="note"
                      value={selectedCustomer.note || ""}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              )}

              {activeTab === "packages" && (
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Paketler
                    </label>
                    <div className="mb-2">
                      <input
                        type="text"
                        placeholder="Paket ara..."
                        value={packageSearchTerm}
                        onChange={(e) => setPackageSearchTerm(e.target.value)}
                        className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                      />
                    </div>
                    <div className="space-y-3 max-h-96 p-3 overflow-y-auto pr-2 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600">
                      {packages
                        .filter((pkg) =>
                          pkg.name
                            .toLowerCase()
                            .includes(packageSearchTerm.toLowerCase())
                        )
                        .reverse()
                        .map((pkg) => (
                          <label
                            key={pkg.id}
                            className="flex items-center space-x-3"
                          >
                            <input
                              type="checkbox"
                              value={pkg.id}
                              checked={(
                                selectedCustomer.company_packages || []
                              ).includes(pkg.id)}
                              onChange={handlePackageChange}
                              className="form-checkbox h-5 w-5 text-blue-600 dark:text-blue-400 rounded focus:ring-blue-400 dark:focus:ring-blue-600"
                            />
                            <span className="text-gray-700 dark:text-gray-300">
                              {pkg.name}
                              <span className="inline-flex items-center ml-2 px-1 py-0.5 text-xs font-medium text-blue-500 bg-blue-100 rounded-full dark:text-blue-300 dark:bg-blue-700">
                                Seans: {pkg.seance_count}
                              </span>
                            </span>
                          </label>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "payments" && (
                <div className="space-y-6">
                  {showPaymentList ? (
                    <>
                      <div className="flex justify-between items-center mb-4">
                        <h4 className="text-lg font-semibold">Ödemeler</h4>
                        <button
                          type="button"
                          onClick={addNewPayment}
                          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150 ease-in-out text-sm flex items-center"
                        >
                          <FiPlus className="mr-2" /> Yeni Ödeme Ekle
                        </button>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {payments.map((payment) => (
                          <div
                            key={payment.id}
                            className="bg-gray-50 dark:bg-gray-700 shadow-sm rounded-lg overflow-hidden cursor-pointer hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
                            onClick={() => handlePaymentClick(payment)}
                          >
                            <div className="p-4">
                              <div className="flex justify-between items-center mb-3">
                                <h5 className="font-semibold text-lg text-gray-800 dark:text-gray-200">
                                  Ödeme #{payment.id}
                                </h5>
                                <span className="text-sm text-gray-500 dark:text-gray-400 bg-gray-100 dark:bg-gray-600 px-2 py-1 rounded-full">
                                  {new Date(
                                    payment.payment_date
                                  ).toLocaleDateString()}
                                </span>
                              </div>
                              <div className="space-y-2">
                                <div className="flex justify-between items-center">
                                  <span className="text-gray-600 dark:text-gray-300 flex items-center">
                                    <FaLiraSign className="mr-1" /> Tutar:
                                  </span>
                                  <span className="font-medium text-gray-800 dark:text-gray-200">
                                    {payment.amount} TL
                                  </span>
                                </div>
                                <div className="flex justify-between items-center">
                                  <span className="text-gray-600 dark:text-gray-300 flex items-center">
                                    <FiCreditCard className="mr-1" /> Ödenen:
                                  </span>
                                  <span className="font-medium text-green-600 dark:text-green-400">
                                    {payment.payed_amount} TL
                                  </span>
                                </div>
                              </div>
                              <div className="mt-3 pt-3 border-t border-gray-200 dark:border-gray-600">
                                <div className="flex justify-between items-center">
                                  <span className="text-sm text-gray-500 dark:text-gray-400">
                                    Kalan:
                                  </span>
                                  <span className="font-medium text-blue-600 dark:text-blue-400">
                                    {(
                                      payment.amount - payment.payed_amount
                                    ).toFixed(2)}{" "}
                                    TL
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="space-y-6">
                      <div className="flex items-center mb-4">
                        <button
                          type="button"
                          onClick={() => setShowPaymentList(true)}
                          className="mr-4 text-blue-500 hover:text-blue-700"
                        >
                          <FiArrowLeft size={24} />
                        </button>
                        <h4 className="text-lg font-semibold">
                          Ödeme Detayları
                        </h4>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Tutar
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <FaLiraSign className="text-gray-400" />
                            </div>
                            <input
                              type="number"
                              name="amount"
                              value={selectedPayment.amount}
                              onChange={handlePaymentInputChange}
                              className="w-full pl-10 px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Ödenen Tutar
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <FiCreditCard className="text-gray-400" />
                            </div>
                            <input
                              type="number"
                              name="payed_amount"
                              value={selectedPayment.payed_amount}
                              onChange={handlePaymentInputChange}
                              className="w-full pl-10 px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Ödeme Tipi
                          </label>
                          <select
                            name="payment_type"
                            value={selectedPayment.payment_type}
                            onChange={handlePaymentInputChange}
                            className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                          >
                            <option value="cash">Nakit</option>
                            <option value="card">Kart</option>
                            <option value="eft">Havale/EFT</option>
                            <option value="other">Diğer</option>
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            İndirim
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <FaLiraSign className="text-gray-400" />
                            </div>
                            <input
                              type="number"
                              name="discount"
                              value={selectedPayment.discount}
                              onChange={handlePaymentInputChange}
                              className="w-full pl-10 px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          Açıklama
                        </label>
                        <textarea
                          name="description"
                          value={selectedPayment.description}
                          onChange={handlePaymentInputChange}
                          className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="flex justify-between items-center">
                        <button
                          type="button"
                          onClick={savePaymentAndInstallments}
                          disabled={isSaveButtonDisabled}
                          className={`px-4 py-2 ${
                            isSaveButtonDisabled
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-green-500 hover:bg-green-600"
                          } text-white rounded-md transition duration-150 ease-in-out text-sm flex items-center`}
                        >
                          <FiSave className="mr-2" />
                          {isSaveButtonDisabled
                            ? "Kaydediliyor..."
                            : "Ödemeyi Kaydet"}
                        </button>
                        <button
                          type="button"
                          onClick={() => deletePayment(selectedPayment.id)}
                          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-150 ease-in-out text-sm flex items-center"
                        >
                          <FiMinus className="mr-2" /> Ödemeyi Sil
                        </button>
                      </div>

                      <div className="mt-6">
                        <button
                          type="button"
                          onClick={toggleInstallmentAccordion}
                          className="flex justify-between items-center w-full px-4 py-2 bg-gray-100 dark:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-500 transition duration-150 ease-in-out"
                        >
                          <span className="font-medium">Taksitler</span>
                          {expandedInstallments ? (
                            <FiChevronUp />
                          ) : (
                            <FiChevronDown />
                          )}
                        </button>
                        <AnimatePresence>
                          {expandedInstallments && (
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                              className="mt-2 space-y-2"
                            >
                              {installments.map((installment, index) => (
                                <div
                                  key={index}
                                  className="flex space-x-2 items-center"
                                >
                                  <input
                                    type="date"
                                    value={formatDate(installment.payment_date)}
                                    onChange={(e) =>
                                      handleInstallmentChange(
                                        index,
                                        "payment_date",
                                        e.target.value
                                      )
                                    }
                                    className="flex-1 px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                                  />
                                  <input
                                    type="number"
                                    value={installment.amount}
                                    onChange={(e) =>
                                      handleInstallmentChange(
                                        index,
                                        "amount",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Taksit tutarı"
                                    className="flex-1 px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                                  />
                                  <input
                                    type="number"
                                    value={installment.payed_amount}
                                    onChange={(e) =>
                                      handleInstallmentChange(
                                        index,
                                        "payed_amount",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Ödenen tutar"
                                    className={`flex-1 px-3 py-2 rounded-md border focus:ring ${
                                      parseFloat(installment.amount) ===
                                      parseFloat(installment.payed_amount)
                                        ? "bg-green-100 dark:bg-green-700 text-green-800 dark:text-green-200 border-green-300 dark:border-green-600 focus:border-green-500 dark:focus:border-green-400"
                                        : "bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300 border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500"
                                    }`}
                                  />
                                  <input
                                    type="text"
                                    value={installment.description}
                                    onChange={(e) =>
                                      handleInstallmentChange(
                                        index,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Açıklama"
                                    className="flex-1 px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => removeInstallment(index)}
                                    className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-150 ease-in-out"
                                  >
                                    <FiMinus />
                                  </button>
                                </div>
                              ))}
                              <div className="flex justify-between items-center mt-4">
                                <button
                                  type="button"
                                  onClick={addInstallment}
                                  className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150 ease-in-out flex items-center text-sm"
                                >
                                  <FiPlus className="mr-1" /> Taksit Ekle
                                </button>
                                <button
                                  type="button"
                                  onClick={savePaymentAndInstallments}
                                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-150 ease-in-out text-sm flex items-center"
                                >
                                  <FiSave className="mr-2" /> Taksitleri Kaydet
                                </button>
                              </div>
                              <div className="mt-4">
                                <h6 className="text-sm font-semibold mb-2">
                                  Toplu Taksit Oluştur
                                </h6>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                  <input
                                    type="date"
                                    name="installment_date"
                                    value={bulkInstallment.installment_date}
                                    onChange={handleBulkInstallmentChange}
                                    className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                                  />
                                  <input
                                    type="number"
                                    name="installment_count"
                                    value={bulkInstallment.installment_count}
                                    onChange={handleBulkInstallmentChange}
                                    min="1"
                                    placeholder="Taksit Sayısı"
                                    className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring"
                                  />
                                </div>
                                <button
                                  type="button"
                                  onClick={createBulkInstallments}
                                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150 ease-in-out text-sm w-full"
                                >
                                  Toplu Taksit Oluştur
                                </button>
                              </div>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="flex justify-end space-x-3 mt-8">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition duration-150 ease-in-out text-sm"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 transition duration-150 ease-in-out text-sm"
                >
                  Kaydet
                </button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
      <ErrorMessage
        message={errorMessage}
        onClose={() => setErrorMessage("")}
      />
      <ErrorMessage
        message={successMessage}
        type="success"
        onClose={() => setSuccessMessage("")}
      />
      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={confirmationAction}
        message={confirmationMessage}
      />
    </AnimatePresence>
  );
};

export default CustomerModal;