import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AiOutlineHome,
  AiOutlineSetting,
  AiOutlineUserAdd,
  AiTwotoneCalendar,
  AiOutlineClose,
  AiTwotoneShopping,
  AiOutlineTable,
  AiOutlineAreaChart
} from 'react-icons/ai';
import { PiPackage } from "react-icons/pi";

const menuItems = [
  { icon: AiOutlineHome, path: '/home', label: 'Ana Sayfa' },
  { icon: AiOutlineUserAdd, path: '/customers', label: 'Müşteriler' },
  { icon: AiTwotoneCalendar, path: '/appointments', label: 'Randevu Oluştur' },
  { icon: AiOutlineTable, path: '/allappointments', label: 'Tüm Randevular' },
  { icon: PiPackage, path: '/packets', label: 'Paketler' },
  { icon: AiTwotoneShopping, path: '/products', label: 'Ürünler' },
  { icon: AiOutlineAreaChart , path: '/statistics', label: 'İstatistik', requiredPermission: 'view_statistics' },
  { icon: AiOutlineSetting, path: '/settings', label: 'Ayarlar' },
];

const NavItem = ({ icon: Icon, path, label, isActive }) => (
  <Link
    to={path}
    className={`p-3 flex flex-col items-center justify-center rounded-xl transition-all duration-300 text-center ${
      isActive
        ? 'bg-blue-600 text-white'
        : 'text-gray-300 hover:bg-gray-700 hover:text-white'
    }`}
    title={label}
  >
    <Icon className={`text-2xl ${isActive ? 'animate-pulse' : 'group-hover:scale-110 transition-transform duration-200'}`} />
    <span className="text-[10px] mt-2 font-medium">{label}</span>
  </Link>
);

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');

  return (
    <aside
      className={`
        sidebar
        ${isOpen ? 'translate-x-0' : '-translate-x-full'}
        md:translate-x-0 fixed md:relative inset-y-0 left-0 z-30
        w-64 md:w-20 bg-gray-900 text-white flex flex-col shadow-lg
        transition-transform duration-300 ease-in-out overflow-x-auto
      `}
    >
      <div className="p-4 flex justify-end md:hidden">
        <button
          className="text-gray-300 hover:text-white"
          onClick={toggleSidebar}
          aria-label="Close sidebar"
        >
          <AiOutlineClose size={24} />
        </button>
      </div>
      <nav className="flex-1 flex flex-col space-y-4 py-6 px-3">
        {menuItems.map((item, index) => (
          (!item.requiredPermission || permissions.includes(item.requiredPermission)) && (
            <NavItem
              key={index}
              {...item}
              isActive={location.pathname === item.path}
            />
          )
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;