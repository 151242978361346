import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../api/api';
import { FiPlus, FiEdit, FiTrash2, FiSearch } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import ErrorMessage from '../components/ErrorMessage';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentProduct, setCurrentProduct] = useState({
        id: null,
        name: '',
        sale_price: '',
        purchase_price: '',
        description: '',
        stock: ''
    });
    const [searchTerm, setSearchTerm] = useState('');

    const fetchProducts = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/api/product/');
            setProducts(response.data);
        } catch (error) {
            setErrorMessage('Ürünleri çekerken bir hata oluştu: ' + error.response?.data?.message || error.message);
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentProduct(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (currentProduct.id) {
                await axiosInstance.patch(`/api/product/${currentProduct.id}/`, currentProduct);
            } else {
                await axiosInstance.post('/api/product/', currentProduct);
            }
            setIsModalOpen(false);
            fetchProducts();
            setCurrentProduct({ id: null, name: '', sale_price: '', purchase_price: '', description: '', stock: '' });
        } catch (error) {
            setErrorMessage('Ürün kaydederken bir hata oluştu: ' + error.response?.data?.message || error.message);
        }
    };

    const handleEdit = (product) => {
        setCurrentProduct(product);
        setIsModalOpen(true);
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`/api/product/${currentProduct.id}/`);
            setIsDeleteModalOpen(false);
            fetchProducts();
        } catch (error) {
            setErrorMessage('Ürün silerken bir hata oluştu: ' + error.response?.data?.message || error.message);
        }
    };

    const filteredProducts = useMemo(() =>
        products.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        [products, searchTerm]);

    return (
        <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8 md:mb-10 text-blue-600 dark:text-blue-400">Ürünler</h2>

                <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                    <button
                        onClick={() => {
                            setCurrentProduct({ id: null, name: '', sale_price: '', purchase_price: '', description: '', stock: '' });
                            setIsModalOpen(true);
                        }}
                        className="mb-4 sm:mb-0 px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors duration-200 flex items-center"
                    >
                        <FiPlus className="mr-2" />
                        Yeni Ürün Ekle
                    </button>
                    <div className="relative w-full sm:w-64">
                        <input
                            type="text"
                            placeholder="Ürün Ara..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full px-4 py-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-500"
                        />
                        <FiSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
                    <div className="overflow-x-auto">
                        <div className="max-h-[600px] overflow-y-auto">
                            <table className="w-full min-w-max table-auto">
                                <thead className="bg-blue-50 dark:bg-gray-700 sticky top-0 z-10">
                                    <tr>
                                        {["Ürün Adı", "Alış Fiyatı", "Satış Fiyatı", "Stok", "Açıklama", "İşlemler"].map((header, index) => (
                                            <th key={index} className="px-4 py-3 text-left text-xs font-medium text-blue-600 dark:text-blue-400 uppercase tracking-wider">{header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                    {filteredProducts.map((product) => (
                                        <tr key={product.id} className="hover:bg-blue-50 dark:hover:bg-gray-700 transition-colors duration-200">
                                            <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">{product.name}</td>
                                            <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">{product.purchase_price}</td>
                                            <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">{product.sale_price}</td>
                                            <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">{product.stock}</td>
                                            <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base dark:text-gray-300">{product.description}</td>
                                            <td className="px-4 py-3 whitespace-nowrap text-sm font-medium">
                                                <button
                                                    onClick={() => handleEdit(product)}
                                                    className="bg-green-100 dark:bg-green-800 text-green-600 dark:text-green-200 hover:bg-green-200 dark:hover:bg-green-700 rounded-full p-2 transition duration-300 mr-3">
                                                    <FiEdit />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setCurrentProduct(product);
                                                        setIsDeleteModalOpen(true);
                                                    }}
                                                    className="bg-red-100 dark:bg-red-800 text-red-600 dark:text-red-200 hover:bg-red-200 dark:hover:bg-red-700 rounded-full p-2 transition duration-300">
                                                    <FiTrash2 />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <AnimatePresence>
                    {isModalOpen && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                        >
                            <motion.div
                                initial={{ scale: 0.9, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.9, opacity: 0 }}
                                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
                            >
                                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">{currentProduct.id ? 'Ürün Düzenle' : 'Yeni Ürün Ekle'}</h3>
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Ürün Adı</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={currentProduct.name}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Alış Fiyatı</label>
                                        <input
                                            type="number"
                                            name="purchase_price"
                                            value={currentProduct.purchase_price}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Satış Fiyatı</label>
                                        <input
                                            type="number"
                                            name="sale_price"
                                            value={currentProduct.sale_price}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Stok</label>
                                        <input
                                            type="number"
                                            name="stock"
                                            value={currentProduct.stock}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Açıklama</label>
                                        <textarea
                                            name="description"
                                            value={currentProduct.description}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                                            rows="3"
                                        ></textarea>
                                    </div>
                                    <div className="flex justify-end space-x-3 mt-6">
                                        <button
                                            type="button"
                                            onClick={() => setIsModalOpen(false)}
                                            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors duration-200"
                                        >
                                            İptal
                                        </button>
                                        <button
                                            type="submit"
                                            className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 transition-colors duration-200"
                                        >
                                            {currentProduct.id ? 'Güncelle' : 'Ekle'}
                                        </button>
                                    </div>
                                </form>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {isDeleteModalOpen && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                        >
                            <motion.div
                                initial={{ scale: 0.9, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.9, opacity: 0 }}
                                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
                            >
                                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">Ürün Silme Onayı</h3>
                                <p className="mb-6 text-gray-700 dark:text-gray-300">Bu ürünü silmek istediğinizden emin misiniz?</p>
                                <div className="flex justify-end space-x-3">
                                    <button
                                        onClick={() => setIsDeleteModalOpen(false)}
                                        className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:gray-500 transition-colors duration-200">
                                        İptal
                                    </button>
                                    <button
                                        onClick={handleDelete}
                                        className="px-4 py-2 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 dark:focus:ring-red-500 transition-colors duration-200"
                                    >
                                        Sil
                                    </button>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <ErrorMessage
                    message={errorMessage}
                    onClose={() => setErrorMessage('')}
                />
            </div>
        </div>
    );
};

export default Products;