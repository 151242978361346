import React, { useState, useEffect } from "react";
import axiosInstance from "../api/api";
import { format, parseISO } from "date-fns";
import {
  FiClock,
  FiUser,
  FiPhone,
  FiPackage,
  FiCalendar,
  FiList,
  FiInfo,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import Calendar from "../components/allappointments/Calendar";
import ErrorMessage from "../components/ErrorMessage";

const Allappointments = () => {
  const [allReservations, setAllReservations] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterType, setFilterType] = useState("all");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [reservationToComplete, setReservationToComplete] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeTab, setActiveTab] = useState("table");

  useEffect(() => {
    fetchAllReservations();
  }, []);

  const fetchAllReservations = async () => {
    try {
      const response = await axiosInstance.get("/api/reservation/");
      setAllReservations(response.data);
    } catch (error) {
      setErrorMessage("Tüm randevuları çekme hatası: " + error.response?.data?.message || error.message);
    }
  };

  const handleDeleteClick = (reservation) => {
    setReservationToDelete(reservation);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/api/reservation/${reservationToDelete.id}/`);
      fetchAllReservations();
      setIsDeleteModalOpen(false);
    } catch (error) {
      setErrorMessage("Randevu silme hatası: " + error.response?.data?.message || error.message);
    }
  };

  const handleCompleteClick = (reservation) => {
    setReservationToComplete(reservation);
    setIsCompleteModalOpen(true);
  };

  const handleComplete = async () => {
    try {
      await axiosInstance.patch(
        `/api/reservation/${reservationToComplete.id}/`,
        { completed: true }
      );
      fetchAllReservations();
      setIsCompleteModalOpen(false);
    } catch (error) {
      setErrorMessage("Randevu tamamlama hatası: " + error.response?.data?.message || error.message);
    }
  };

  const filteredReservations = allReservations.filter((reservation) => {
    const nameMatch = reservation.customer_info
      ? reservation.customer_info.name
          .toLowerCase()
          .includes(filterName.toLowerCase())
      : reservation.description
          .toLowerCase()
          .includes(filterName.toLowerCase());
    const statusMatch =
      filterStatus === "all" ||
      (filterStatus === "completed" && reservation.completed) ||
      (filterStatus === "pending" && !reservation.completed);
    const typeMatch =
      filterType === "all" ||
      (filterType === "control" && reservation.is_control) ||
      (filterType === "normal" && !reservation.is_control) ||
      (filterType === "daily" && !reservation.customer_info);
    return nameMatch && statusMatch && typeMatch;
  });

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8 md:mb-10 text-blue-600 dark:text-blue-400">
          Tüm Randevular
        </h2>

        <div className="mb-6 flex justify-center">
          <motion.div className="bg-gray-300 dark:bg-gray-700 p-1 rounded-lg flex gap-2">
            <motion.button
              layout
              onClick={() => setActiveTab("table")}
              className={`px-4 py-2 rounded-md ${
                activeTab === "table"
                  ? "bg-blue-500 text-white"
                  : "bg-transparent text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600"
              }`}
            >
              <FiList className="inline-block mr-2" />
              Tablo Görünümü
            </motion.button>
            <motion.button
              layout
              onClick={() => setActiveTab("calendar")}
              className={`px-4 py-2 rounded-md ${
                activeTab === "calendar"
                  ? "bg-blue-500 text-white"
                  : "bg-transparent text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600"
              }`}
            >
              <FiCalendar className="inline-block mr-2" />
              Takvim Görünümü
            </motion.button>
          </motion.div>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            {activeTab === "table" ? (
              <>
                <div className="mb-4 flex flex-col md:flex-row gap-4">
                  <div className="flex-grow">
                    <input
                      type="text"
                      placeholder="Müşteri Adı veya Açıklama ile Ara"
                      value={filterName}
                      onChange={(e) => setFilterName(e.target.value)}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <select
                      value={filterStatus}
                      onChange={(e) => setFilterStatus(e.target.value)}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                    >
                      <option value="all">Tüm Durumlar</option>
                      <option value="completed">Tamamlanan</option>
                      <option value="pending">Bekleyen</option>
                    </select>
                  </div>
                  <div>
                    <select
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50"
                    >
                      <option value="all">Tüm Türler</option>
                      <option value="normal">Normal</option>
                      <option value="control">Kontrol</option>
                      <option value="daily">Günübirlik</option>
                    </select>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
                  <div className="overflow-x-auto">
                    <div className="max-h-[600px] overflow-y-auto">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Tarih
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Saat
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Müşteri / Açıklama
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Telefon
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Paket
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Tür
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Durum
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              İşlemler
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                          {filteredReservations.map((reservation) => (
                            <tr
                              key={reservation.id}
                              className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                            >
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                                {format(
                                  parseISO(reservation.reservation_date),
                                  "dd.MM.yyyy"
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                                <div className="flex items-center">
                                  <FiClock className="mr-2 text-blue-400" />
                                  {reservation.reservation_time}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  {reservation.customer_info ? (
                                    <>
                                      <FiUser className="mr-2 text-blue-400" />
                                      <span className="text-sm text-gray-800 dark:text-gray-200">
                                        {reservation.customer_info.name}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <FiInfo className="mr-2 text-blue-400" />
                                      <span className="text-sm text-gray-800 dark:text-gray-200">
                                        {reservation.description}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <FiPhone className="mr-2 text-blue-400" />
                                  <span className="text-sm text-gray-800 dark:text-gray-200">
                                    {reservation.customer_info
                                      ? reservation.customer_info.phone
                                      : "-"}
                                  </span>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <FiPackage className="mr-2 text-blue-400" />
                                  <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200">
                                    {reservation.company_package_info
                                      ? reservation.company_package_info.name
                                      : "-"}
                                  </span>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span
                                  className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                    !reservation.customer_info
                                      ? "bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200"
                                      : reservation.is_control
                                      ? "bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200"
                                      : "bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200"
                                  }`}
                                >
                                  {!reservation.customer_info
                                    ? "Günübirlik"
                                    : reservation.is_control
                                    ? "Kontrol"
                                    : "Normal"}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span
                                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                    reservation.completed
                                      ? "bg-green-100 dark:bg-green-800 text-green-800 dark:text-green-200"
                                      : "bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-200"
                                  }`}
                                >
                                  {reservation.completed
                                    ? "Tamamlandı"
                                    : "Bekliyor"}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                {!reservation.completed && (
                                  <button
                                    onClick={() =>handleCompleteClick(reservation)
                                    }
                                    className="text-blue-600 dark:text-blue-400 hover:text-blue-900 dark:hover:text-blue-300 mr-4"
                                  >
                                    Tamamla
                                  </button>
                                )}
                                <button
                                  onClick={() => handleDeleteClick(reservation)}
                                  className="text-red-600 dark:text-red-400 hover:text-red-900 dark:hover:text-red-300"
                                >
                                  Gelmedi
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Calendar reservations={allReservations} />
            )}
          </motion.div>
        </AnimatePresence>

        <AnimatePresence>
          {isDeleteModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
              >
                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">
                  Randevu Silme Onayı
                </h3>
                <p className="mb-6 text-gray-700 dark:text-gray-300">
                  Bu randevuyu silmek istediğinizden emin misiniz?
                </p>
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsDeleteModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors duration-200"
                  >
                    İptal
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 dark:focus:ring-red-500 transition-colors duration-200"
                  >
                    Sil
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isCompleteModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md"
              >
                <h3 className="text-2xl font-semibold mb-6 text-blue-600 dark:text-blue-400">
                  Randevu Tamamlama Onayı
                </h3>
                <p className="mb-6 text-gray-700 dark:text-gray-300">
                  Bu randevuyu tamamlamak istediğinizden emin misiniz?
                </p>
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsCompleteModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors duration-200"
                  >
                    İptal
                  </button>
                  <button
                    onClick={handleComplete}
                    className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 transition-colors duration-200"
                  >
                    Tamamla
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <ErrorMessage
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      </div>
    </div>
  );
};

export default Allappointments;