import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../api/api';
import { FiPlus, FiTrash2, FiEdit, FiSearch } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import ErrorMessage from '../components/ErrorMessage';

const Packets = () => {
  const [packages, setPackages] = useState([]);
  const [newPackage, setNewPackage] = useState({
    id: null,
    name: '',
    personal_count: 0,
    price: '',
    description: '',
    seance_count: 0
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const fetchPackages = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/company-package/');
      setPackages(response.data.reverse());
    } catch (error) {
      setErrorMessage('Paket verisi çekme hatası: ' + error.response?.data?.message || error.message);
    }
  }, []);

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNewPackage(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      if (newPackage.id) {
        await axiosInstance.put(`/api/company-package/${newPackage.id}/`, newPackage);
      } else {
        await axiosInstance.post('/api/company-package/', newPackage);
      }
      setNewPackage({ id: null, name: '', personal_count: 0, price: '', description: '', seance_count: 0 });
      setIsEditModalOpen(false);
      fetchPackages();
    } catch (error) {
      setErrorMessage('Paket işlemi hatası: ' + error.response?.data?.message || error.message);
    }
  }, [newPackage, fetchPackages]);

  const handleDeleteClick = useCallback((pkg) => {
    setPackageToDelete(pkg);
    setIsDeleteModalOpen(true);
  }, []);

  const handleEditClick = useCallback((pkg) => {
    setNewPackage(pkg);
    setIsEditModalOpen(true);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      await axiosInstance.delete(`/api/company-package/${packageToDelete.id}/`);
      fetchPackages();
      setIsDeleteModalOpen(false);
    } catch (error) {
      setErrorMessage('Paket silme hatası: ' + error.response?.data?.message || error.message);
    }
  }, [packageToDelete, fetchPackages]);

  const filteredPackages = useMemo(() => 
    packages.filter(pkg => 
      pkg.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [packages, searchTerm]
  );

  const packageList = useMemo(() => filteredPackages.map((pkg) => (
    <tr key={pkg.id} className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200">
      <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">{pkg.name}</td>
      <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">{pkg.personal_count}</td>
      <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">{pkg.price}</td>
      <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">{pkg.seance_count}</td>
      <td className="px-4 py-3 text-gray-800 dark:text-gray-200">{pkg.description}</td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => handleEditClick(pkg)}
            className="bg-green-100 dark:bg-green-800 text-green-600 dark:text-green-200 hover:bg-green-200 dark:hover:bg-green-700 rounded-full p-2 transition duration-300">
            <FiEdit />
          </button>
          <button
            onClick={() => handleDeleteClick(pkg)}
            className="bg-red-100 dark:bg-red-800 text-red-600 dark:text-red-200 hover:bg-red-200 dark:hover:bg-red-700 rounded-full p-2 transition duration-300">
            <FiTrash2 />
          </button>
        </div>
      </td>
    </tr>
  )), [filteredPackages, handleDeleteClick, handleEditClick]);

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8 md:mb-10 text-blue-600 dark:text-blue-400">Paketler</h2>

        <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
          <button
            onClick={() => {
              setNewPackage({ id: null, name: '', personal_count: 0, price: '', description: '', seance_count: 0 });
              setIsEditModalOpen(true);
            }}
            className="mb-4 sm:mb-0 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition duration-150 ease-in-out flex items-center"
          >
            <FiPlus className="mr-2" /> Yeni Paket Ekle
          </button>
          <div className="relative w-full sm:w-64">
            <input
              type="text"
              placeholder="Paket Ara..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-500"
            />
            <FiSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto max-h-[600px]">
            <table className="w-full min-w-full table-auto">
              <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
                <tr>
                  {["Paket Adı", "Çalışan Sayısı", "Fiyat", "Seans Sayısı", "Açıklama", "İşlemler"].map((header, index) => (
                    <th key={index} className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {packageList}
              </tbody>
            </table>
          </div>
        </div>

        <AnimatePresence>
          {isDeleteModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-6 md:p-8 rounded-lg shadow-xl w-full max-w-md mx-auto"
              >
                <h3 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-200">Paketi Sil</h3>
                <p className="text-gray-600 dark:text-gray-400 mb-6">
                  "{packageToDelete?.name}" isimli paketi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
                </p>
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsDeleteModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition duration-150 ease-in-out"
                  >
                    İptal
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 dark:focus:ring-red-500 transition duration-150 ease-in-out"
                  >
                    Sil
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isEditModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-6 md:p-8 rounded-lg shadow-xl w-full max-w-md mx-auto"
              >
                <h3 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-200">
                  {newPackage.id ? 'Paketi Düzenle' : 'Yeni Paket Ekle'}
                </h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="edit-name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Paket Adı</label>
                    <input
                      id="edit-name"
                      type="text"
                      name="name"
                      value={newPackage.name}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="edit-personal_count" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Çalışan Sayısı</label>
                    <input
                      id="edit-personal_count"
                      type="number"
                      name="personal_count"
                      value={newPackage.personal_count}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="edit-price" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Fiyat</label>
                    <input
                      id="edit-price"
                      type="number"
                      name="price"
                      value={newPackage.price}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="edit-seance_count" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Seans Sayısı</label>
                    <input
                      id="edit-seance_count"
                      type="number"
                      name="seance_count"
                      value={newPackage.seance_count}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-700 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="edit-description" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Açıklama</label>
                    <textarea
                      id="edit-description"
                      name="description"
                      value={newPackage.description}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="flex justify-end space-x-3 mt-6">
                    <button
                      type="button"
                      onClick={() => setIsEditModalOpen(false)}
                      className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition duration-150 ease-in-out"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 transition duration-150 ease-in-out"
                    >
                      {newPackage.id ? 'Güncelle' : 'Ekle'}
                    </button>
                  </div>
                </form>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <ErrorMessage
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      </div>
    </div>
  );
};

export default Packets;