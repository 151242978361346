import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { format, parseISO } from "date-fns";
import { tr } from "date-fns/locale";
import {
  FiUser,
  FiPhone,
  FiMail,
  FiCalendar,
  FiClock,
  FiCreditCard,
  FiX,
  FiArrowUp,
  FiEdit,
  FiChevronDown,
} from "react-icons/fi";
import { AiTwotoneShopping } from "react-icons/ai";
import axiosInstance from "../../api/api";
import ErrorMessage from "../ErrorMessage";

const CustomerDetailModal = ({ customer, isOpen, onClose, onUpdate }) => {
  const [activeTab, setActiveTab] = useState("reservations");
  const [expandedDescription, setExpandedDescription] = useState(null);
  const [editingDescription, setEditingDescription] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setExpandedDescription(null);
      setEditingDescription(null);
      setEditedDescription("");
    }
  }, [isOpen]);

  const toggleDescription = (id) => {
    setExpandedDescription((prev) => (prev === id ? null : id));
    setEditingDescription(null);
    setEditedDescription("");
  };

  const handleEdit = (id, description) => {
    setEditingDescription(id);
    setEditedDescription(description);
  };

  const handleSave = async (id) => {
    try {
      await axiosInstance.patch(`/api/history-payment/${id}/`, {
        description: editedDescription,
      });
      onUpdate(id, editedDescription);
      setEditingDescription(null);
    } catch (error) {
      console.error("Error updating description:", error);
      setErrorMessage("Açıklama güncellenirken bir hata oluştu.");
    }
  };

  const handleCancel = () => {
    setEditingDescription(null);
    setEditedDescription("");
  };

  if (!isOpen || !customer) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white dark:bg-gray-800 p-6 md:p-8 rounded-lg shadow-xl w-full max-w-4xl mx-auto"
      >
        <h3 className="text-2xl font-bold mb-6 text-blue-600 dark:text-blue-400">
          Müşteri Detayları
        </h3>
        <div className="flex flex-col md:flex-row mb-6">
          <div className="w-full md:w-1/3 mb-6 md:mb-0 md:mr-6">
            <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
              <div className="flex items-center justify-center mb-4">
                <FiUser className="text-6xl text-blue-500 dark:text-blue-400" />
              </div>
              <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2">
                {customer.name}
              </h4>
              <p className="text-gray-600 dark:text-gray-400 flex items-center mb-2">
                <FiPhone className="mr-2" /> {customer.phone}
              </p>
              <p className="text-gray-600 dark:text-gray-400 flex items-center mb-4">
                <FiMail className="mr-2" /> {customer.email}
              </p>
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => setActiveTab("reservations")}
                  className={`flex-1 px-3 py-2 rounded-md ${
                    activeTab === "reservations"
                      ? "bg-blue-500 dark:bg-blue-600 text-white"
                      : "bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300"
                  }`}
                >
                  <FiCalendar className="inline mr-2" />
                  Randevular
                </button>
                <button
                  onClick={() => setActiveTab("payments")}
                  className={`flex-1 px-3 py-2 rounded-md ${
                    activeTab === "payments"
                      ? "bg-blue-500 dark:bg-blue-600 text-white"
                      : "bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300"
                  }`}
                >
                  <FiCreditCard className="inline mr-2" />
                  Ödemeler
                </button>
                <button
                  onClick={() => setActiveTab("remainingSessions")}
                  className={`flex-1 px-3 py-2 rounded-md ${
                    activeTab === "remainingSessions"
                      ? "bg-blue-500 dark:bg-blue-600 text-white"
                      : "bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300"
                  }`}
                >
                  <FiClock className="inline mr-2" />
                  Kalan Seanslar
                </button>
                <button
                  onClick={() => setActiveTab("purchasedProducts")}
                  className={`flex-1 px-3 py-2 rounded-md ${
                    activeTab === "purchasedProducts"
                      ? "bg-blue-500 dark:bg-blue-600 text-white"
                      : "bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300"
                  }`}
                >
                  <AiTwotoneShopping className="inline mr-2" />
                  Satın Alınanlar
                </button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/3 h-96">
            <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden h-full border border-gray-200 dark:border-gray-700">
              <div className="overflow-y-auto h-full">
                {activeTab === "reservations" && (
                  <div>
                    <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 p-4 bg-gray-100 dark:bg-gray-700 sticky top-0">
                      Randevu Geçmişi
                    </h4>
                    <table className="w-full">
                      <thead className="bg-gray-50 dark:bg-gray-700 sticky top-14">
                        <tr>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Tarih
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Saat
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Paket
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {customer.reservation_history.map((reservation) => (
                          <tr key={reservation.id}>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                              <FiCalendar className="inline mr-2" />
                              {format(
                                parseISO(reservation.reservation_date),
                                "dd MMM yyyy",
                                { locale: tr }
                              )}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                              <FiClock className="inline mr-2" />
                              {reservation.reservation_time.slice(0, 5)}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                              {reservation.company_package_info.name}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {activeTab === "payments" && (
                  <div>
                    <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 p-4 bg-gray-100 dark:bg-gray-700 sticky top-0">
                      Ödeme Geçmişi
                    </h4>
                    <table className="w-full">
                      <thead className="bg-gray-50 dark:bg-gray-700 sticky top-14">
                        <tr>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Dosya
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Tarih
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Tutar
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-blue-500 dark:text-blue-400 uppercase tracking-wider">
                            Ödenen
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            İndirim
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Ödeme Yöntemi
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Açıklama
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {customer.payment_history.map((payment) => (
                          <React.Fragment key={payment.id}>
                            <tr>

                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                {payment.payment}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                {format(
                                  parseISO(payment.created_at),
                                  "dd MMM yyyy",
                                  { locale: tr }
                                )}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                {payment.amount}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                {payment.payed_amount}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                {payment.discount || "-"}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                {payment.payment_type === "eft"
                                  ? "EFT"
                                  : payment.payment_type === "cash"
                                  ? "Nakit"
                                  : payment.payment_type === "card"
                                  ? "Kart"
                                  : payment.payment_type === "other"
                                  ? "Diğer"
                                  : payment.payment_type}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                <button
                                  onClick={() => toggleDescription(payment.id)}
                                  className="text-left hover:underline focus:outline-none flex items-center"
                                >
                                  <span>Oku...</span>
                                  <FiChevronDown className="ml-1" />
                                </button>
                              </td>
                            </tr>
                            <AnimatePresence>
                              {expandedDescription === payment.id && (
                                <motion.tr
                                  initial={{ opacity: 0, height: 0 }}
                                  animate={{ opacity: 1, height: "auto" }}
                                  exit={{ opacity: 0, height: 0 }}
                                  transition={{ duration: 0.3 }}
                                >
                                  <td
                                    colSpan="6"
                                    className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400"
                                  >
                                    <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded relative">
                                      <button
                                        onClick={() => {
                                          setExpandedDescription(null);
                                          setEditingDescription(null);
                                          setEditedDescription("");
                                        }}
                                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                                      >
                                        <FiX />
                                      </button>
                                      <p className="font-semibold mb-2 flex items-center">
                                        <FiArrowUp className="mr-1" /> Ürün
                                        Açıklaması:
                                      </p>
                                      {editingDescription === payment.id ? (
                                        <div className="flex flex-col">
                                          <textarea
                                            value={editedDescription}
                                            onChange={(e) =>
                                              setEditedDescription(
                                                e.target.value
                                              )
                                            }
                                            className="w-full p-2 h-20 rounded mb-2 bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
                                          />
                                          <div className="flex justify-end space-x-2">
                                            <button
                                              onClick={handleCancel}
                                              className="px-3 py-1 bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors duration-200"
                                            >
                                              İptal
                                            </button>
                                            <button
                                              onClick={() =>
                                                handleSave(payment.id)
                                              }
                                              className="px-3 py-1 bg-green-500 dark:bg-green-600 text-white rounded hover:bg-green-600 dark:hover:bg-green-700 transition-colors duration-200"
                                            >
                                              Kaydet
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="flex items-center justify-between">
                                          <span>{payment.description}</span>
                                          <button
                                            onClick={() =>
                                              handleEdit(
                                                payment.id,
                                                payment.description
                                              )
                                            }
                                            className="text-blue-500 hover:text-blue-600"
                                          >
                                            <FiEdit size={20} />
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </motion.tr>
                              )}
                            </AnimatePresence>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {activeTab === "remainingSessions" && (
                  <div>
                    <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 p-4 bg-gray-100 dark:bg-gray-700 sticky top-0">
                      Kalan Seanslar
                    </h4>
                    <table className="w-full">
                      <thead className="bg-gray-50 dark:bg-gray-700 sticky top-14">
                        <tr>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Seans Adı
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Kalan Seans
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {customer.used_seance_counts.map((seance, index) => (
                          <tr key={index}>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                              {Object.keys(seance)[0]}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                              {Object.values(seance)[0]}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {activeTab === "purchasedProducts" && (
                  <div>
                    <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 p-4 bg-gray-100 dark:bg-gray-700 sticky top-0">
                      Satın Alınan Ürünler
                    </h4>
                    <table className="w-full">
                      <thead className="bg-gray-50 dark:bg-gray-700 sticky top-14">
                        <tr>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Ürün Adı
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Satış Fiyatı
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Açıklama
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {customer.product_history.map((productHistory) => (
                          <React.Fragment key={productHistory.id}>
                            <tr>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                {productHistory.product.name}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                {productHistory.product.sale_price} TL
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                                <button
                                  onClick={() =>
                                    toggleDescription(productHistory.id)
                                  }
                                  className="text-left hover:underline focus:outline-none flex items-center"
                                >
                                  <span>Oku...</span>
                                  <FiChevronDown className="ml-1" />
                                </button>
                              </td>
                            </tr>
                            <AnimatePresence>
                              {expandedDescription === productHistory.id && (
                                <motion.tr
                                  initial={{ opacity: 0, height: 0 }}
                                  animate={{ opacity: 1, height: "auto" }}
                                  exit={{ opacity: 0, height: 0 }}
                                  transition={{ duration: 0.3 }}
                                >
                                  <td
                                    colSpan="3"
                                    className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400"
                                  >
                                    <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded relative">
                                      <button
                                        onClick={() =>
                                          setExpandedDescription(null)
                                        }
                                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                                      >
                                        <FiX />
                                      </button>
                                      <p className="font-semibold mb-2 flex items-center">
                                        <FiArrowUp className="mr-1" /> Ürün
                                        Açıklaması:
                                      </p>
                                      {productHistory.product.description}
                                    </div>
                                  </td>
                                </motion.tr>
                              )}
                            </AnimatePresence>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition duration-150 ease-in-out"
          >
            Kapat
          </button>
        </div>
      </motion.div>
      <ErrorMessage
        message={errorMessage}
        type="success"
        onClose={() => setErrorMessage("")}
      />
    </motion.div>
  );
};

export default CustomerDetailModal;