import React, { useState, useEffect } from "react";
import {
  format,
  startOfMonth,
  isSameMonth,
  isSameDay,
  parseISO,
  addMonths,
  subMonths,
  startOfWeek,
  addDays,
} from "date-fns";
import { tr } from "date-fns/locale";
import {
  FiChevronLeft,
  FiChevronRight,
  FiClock,
  FiUser,
  FiPackage,
  FiCheck,
  FiX,
  FiPhone,
  FiCalendar,
  FiInfo,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";

const Calendar = ({ reservations }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarDays, setCalendarDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    const start = startOfWeek(startOfMonth(currentDate), { weekStartsOn: 1 });
    const calendarDays = [...Array(42)].map((_, index) =>
      addDays(start, index)
    );
    setCalendarDays(calendarDays);
  }, [currentDate]);

  const nextMonth = () => setCurrentDate(addMonths(currentDate, 1));
  const prevMonth = () => setCurrentDate(subMonths(currentDate, 1));

  const getDayReservations = (day) => {
    return reservations.filter((res) =>
      isSameDay(parseISO(res.reservation_date), day)
    );
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={prevMonth}
          className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200"
        >
          <FiChevronLeft className="text-gray-600 dark:text-gray-300 text-xl" />
        </button>
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
          {format(currentDate, "MMMM yyyy", { locale: tr })}
        </h2>
        <button
          onClick={nextMonth}
          className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200"
        >
          <FiChevronRight className="text-gray-600 dark:text-gray-300 text-xl" />
        </button>
      </div>
      <div
        className="grid lg:grid-cols-7 md:grid-cols-7 gap-2 overflow-x-auto grid-cols-1"
        style={{ maxHeight: "calc(100vh - 250px)" }}
      >
        {calendarDays.map((day, index) => {
          const dayReservations = getDayReservations(day);
          const isToday = isSameDay(day, new Date());
          return (
            <div
              key={index}
              onClick={() => setSelectedDay(day)}
              className={`p-2 border border-gray-200 dark:border-gray-700 rounded-lg transition duration-200 cursor-pointer
                ${
                  !isSameMonth(day, currentDate)
                    ? "bg-gray-50 dark:bg-gray-900 opacity-50"
                    : ""
                }
                ${
                  isToday
                    ? "bg-blue-100 dark:bg-blue-900 border-blue-300 dark:border-blue-600"
                    : ""
                }
                ${dayReservations.length > 0 ? "hover:shadow-md" : ""}
              `}
            >
              <div
                className={`text-right text-sm font-semibold flex-shrink-0 ${
                  isToday
                    ? "text-blue-600 dark:text-blue-400"
                    : "text-gray-700 dark:text-gray-300"
                } mb-1`}
              >
                {format(day, "d EE", { locale: tr })}
              </div>
              <div className="space-y-1">
                {dayReservations.slice(0, 3).map((res, i) => (
                  <div
                    key={i}
                    className={`text-xs p-1 rounded-full flex items-center justify-between
                      ${
                        res.customer_info
                          ? res.is_control
                            ? "bg-purple-100 dark:bg-purple-800 text-purple-800 dark:text-purple-200"
                            : res.completed
                            ? "bg-green-100 dark:bg-green-800 text-green-800 dark:text-green-200"
                            : "bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-200"
                          : "bg-blue-100 dark:bg-blue-800 text-blue-800 dark:text-blue-200"
                      }`}
                  >
                    {res.customer_info ? (
                      <FiUser className="mr-1" />
                    ) : (
                      <FiInfo className="mr-1" />
                    )}
                    <span className="truncate flex-grow">
                      {res.customer_info
                        ? res.customer_info.name
                        : "Günübirlik"}
                    </span>
                    {res.completed ? (
                      <FiCheck className="ml-1" />
                    ) : (
                      <FiClock className="ml-1" />
                    )}
                  </div>
                ))}
                {dayReservations.length > 3 && (
                  <div className="text-xs text-gray-500 dark:text-gray-400 text-center">
                    +{dayReservations.length - 3} daha
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <AnimatePresence>
        {selectedDay && (
          <DayDetailModal
            day={selectedDay}
            reservations={getDayReservations(selectedDay)}
            onClose={() => setSelectedDay(null)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const DayDetailModal = ({ day, reservations, onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">
            <FiCalendar className="inline-block mr-2" />
            {format(day, "d MMMM yyyy", { locale: tr })}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <FiX className="w-6 h-6" />
          </button>
        </div>
        {reservations.length === 0 ? (
          <p className="text-gray-600 dark:text-gray-400">
            Bu gün için randevu bulunmamaktadır.
          </p>
        ) : (
          <div
            className="overflow-x-auto"
            style={{ maxHeight: "calc(90vh - 100px)" }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {reservations.map((res, index) => (
                <div
                  key={index}
                  className="bg-gray-50 dark:bg-gray-700 rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow duration-200"
                >
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        {res.customer_info
                          ? res.customer_info.name
                          : "Günübirlik Müşteri"}
                      </h4>
                      {res.customer_info && (
                        <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                          <FiPhone className="mr-1" />
                          {res.customer_info.phone}
                        </p>
                      )}
                    </div>
                    <span
                      className={`px-2 py-1 text-xs font-semibold rounded-full ${
                        res.customer_info
                          ? res.is_control
                            ? "bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-200"
                            : "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-200"
                          : "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-200"
                      }`}
                    >
                      {res.customer_info
                        ? res.is_control
                          ? "Kontrol"
                          : "Normal"
                        : "Günübirlik"}
                    </span>
                  </div>
                  <div className="mt-2">
                    <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                      <FiClock className="mr-1" />
                      {res.reservation_time}
                    </p>
                    {res.customer_info && (
                      <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                        <FiPackage className="mr-1" />
                        {res.company_package_info.name}
                      </p>
                    )}
                    {!res.customer_info && res.description && (
                      <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                        <FiInfo className="mr-1" />
                        {res.description}
                      </p>
                    )}
                  </div>
                  <div className="mt-3">
                    <span
                      className={`px-2 py-1 text-xs font-semibold rounded-full ${
                        res.completed
                          ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-200"
                          : "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-200"
                      }`}
                    >
                      {res.completed ? "Tamamlandı" : "Bekliyor"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Calendar;